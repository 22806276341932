import { Collapse } from '@finalytic/components';
import { showApiErrorNotification, useApiClient } from '@finalytic/data';
import { LazyTable, type MRT_ColumnDef } from '@finalytic/table';
import {
  type Maybe,
  formatCurrency,
  groupBy,
  sum,
  toTitleCase,
} from '@finalytic/utils';
import {
  Box,
  Center,
  Group,
  HoverCard,
  LoadingOverlay,
  rem,
} from '@mantine/core';
import { Text } from '@mantine/core';
import {
  keepPreviousData,
  useQuery as useTanstackQuery,
} from '@tanstack/react-query';
import { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FEE_TEMPLATES } from '../../../overview/_components';
import { useFeeForm } from '../../useFeeForm';
import { useFeeAccountsTableFilter } from '../FeeAccountsTable';
import type { FeeReservationPreview } from './useFeeReservationPreviewQuery';

export type PreviewRow = {
  id: string;
  type: 'financial' | 'fee' | 'deposit' | 'expense';
  title: string;
  centTotal: number;
  accounts: { id: string; centTotal: number }[];
  subRows?: PreviewRow[];
};

function usePreviewQuery(reservationId: Maybe<string>) {
  const $api = useApiClient();
  const methods = useFeeForm();

  const recurringFeeId = useWatch({
    control: methods.control,
    name: 'id',
  });

  const linkedAccountIds = useWatch({
    control: methods.control,
    name: 'linkedAccountIds',
  });
  const title = useWatch({
    control: methods.control,
    name: 'title',
  });
  const type = useWatch({
    control: methods.control,
    name: 'type',
  });

  const defaultRateBasePoints = useWatch({
    control: methods.control,
    name: 'defaultRateBasePoints',
  });

  const bookingChannelsFilter = useWatch({
    control: methods.control,
    name: 'bookingChannelsFilter',
  });

  const creditAccountId = useWatch({
    control: methods.control,
    name: 'creditAccountId',
  });

  const debitAccountId = useWatch({
    control: methods.control,
    name: 'debitAccountId',
  });

  const revenueRecognition = useWatch({
    control: methods.control,
    name: 'revenueRecognition',
  });

  const statusFilter = useWatch({
    control: methods.control,
    name: 'statusFilter',
  });

  const taxRateId = useWatch({
    control: methods.control,
    name: 'taxRateId',
  });

  return useTanstackQuery({
    enabled: !!reservationId,
    placeholderData: keepPreviousData,
    queryKey: [
      'fee-reservation-preview',
      reservationId,
      linkedAccountIds,
      title,
      defaultRateBasePoints,
      type,
      bookingChannelsFilter,
      creditAccountId,
      debitAccountId,
      revenueRecognition,
      statusFilter,
      taxRateId,
    ],
    queryFn: async () => {
      if (!reservationId) return null;

      const res = await $api.POST('/recurring-fees/preview', {
        body: {
          reservationId,
          type,
          defaultRate: defaultRateBasePoints,
          formula: FEE_TEMPLATES[type].config.formulaTemplate.replace(
            'accounts',
            linkedAccountIds.map((x) => `"${x}"`).join(' + ')
          ),
          creditAccountId,
          debitAccountId,
          bookingChannelsFilter:
            bookingChannelsFilter === 'all-channels'
              ? null
              : bookingChannelsFilter,
          revenueRecognition,
          statusFilter,
          taxRateId,
          id: recurringFeeId ?? undefined,
        },
      });

      if (res.error) {
        showApiErrorNotification({
          error: res.error,
          title: 'Failed to preview fee',
          defaultMessage:
            'We failed to preview the fee. Please select a different reservation and try again. Contact support if the issue persists.',
        });

        throw new Error('Failed to preview fee');
      }

      return res.data;
    },
  });
}

export const FeeReservationPreviewTable = ({
  reservation,
}: {
  reservation: FeeReservationPreview;
}) => {
  const { setFilter } = useFeeAccountsTableFilter();

  const {
    data: preview,
    isFetching,
    isInitialLoading,
  } = usePreviewQuery(reservation?.id);
  const currency = reservation?.currency ?? 'usd';

  type PreviewLine = NonNullable<typeof preview>['lines'][number] & {
    subRows?: PreviewLine[];
    group?: LineGroup;
  };

  type LineGroup = 'financial' | 'feeAndCommission' | 'deposit' | 'expense';

  const lines = useMemo(() => {
    return Object.entries(
      groupBy(preview?.lines ?? [], (x) => {
        if (x.type === 'expense') return 'expense';
        if (x.type === 'deposit') return 'deposit';

        if (x.party === 'manager') return 'feeAndCommission';
        return 'financial';
      })
    ).map<PreviewLine>(([t, lines]) => {
      const group = t as LineGroup;

      const name = (() => {
        if (group === 'feeAndCommission') return 'Fee & Commissions';
        if (group === 'financial') return 'Financials';
        return toTitleCase(group);
      })();

      return {
        amount: sum(lines, 'amount'),
        subRows: lines,
        fee: sum(lines, 'fee'),
        name,
        group: group,
        status: lines.some((x) => x.status === 'active')
          ? 'active'
          : 'inactive',
        type: lines[0].type,
        account: null,
        lineType: null,
        recurringFee: null,
        transaction: null,
      };
    });
  }, [preview?.lines]).sort((a, b) => {
    const order: Record<LineGroup, number> = {
      financial: 0,
      feeAndCommission: 1,
      deposit: 2,
      expense: 3,
    };

    return (
      (order[a.group ?? 'expense'] ?? 0) - (order[b.group ?? 'expense'] ?? 0)
    );
  });

  const columns = useMemo<MRT_ColumnDef<PreviewLine>[]>(
    () => [
      {
        header: 'Item',
        Header: '',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: {
          px: 'xs',
        },
        Footer: () => (
          <Text span fw={500}>
            Total charged:
          </Text>
        ),
        AggregatedCell: ({ row }) => {
          const title = row.original.name;
          return <Text fw={500}>{title}</Text>;
        },
        Cell: ({ row }) => {
          const title = row.original.name;
          const fw = !row.parentId ? 500 : undefined;
          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          // const accountIds = row.original.accounts.map((x) => x.id);

          // const accounts = props.accounts.filter((x) =>
          //   accountIds.includes(x.id)
          // );

          return (
            <HoverCard
              disabled={true}
              shadow="md"
              withArrow
              withinPortal
              position="top"
              radius="md"
            >
              <HoverCard.Target>
                <Box>
                  <Text td={td} fw={fw}>
                    {title}
                  </Text>
                </Box>
              </HoverCard.Target>
              <HoverCard.Dropdown>
                <Text fw={500} mb={rem(5)}>
                  Accounts
                </Text>
                {/* {accounts.length ? (
                  <List icon={null} size="sm">
                    {accounts.map((x) => (
                      <List.Item key={x.id}>
                        <Anchor
                          component="button"
                          onClick={() => setFilter({ search: x.title })}
                          c="dark"
                        >
                          {x.title}
                        </Anchor>
                      </List.Item>
                    ))}
                  </List>
                ) : (
                  <Text c="gray">
                    {row.original.type === 'financial'
                      ? 'No accounts mapped to this line'
                      : 'No accounts found'}
                  </Text>
                )} */}
              </HoverCard.Dropdown>
            </HoverCard>
          );
        },
      },
      {
        header: 'Fee',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
          }),
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
          }),
        },
        mantineTableHeadCellProps: {
          align: 'right',
          pr: 'xs',
          sx: (theme) => ({
            backgroundColor: theme.colors.yellow[0],
            fontWeight: 400,
            color: theme.colors.gray[7],
          }),
        },
        AggregatedCell: ({ row }) => {
          const fee = row.original.fee;

          if (!fee) return '-';

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Text td={td} fw={500}>
              {formatCurrency(fee / 100, currency)}
            </Text>
          );
        },
        Cell: ({ row }) => {
          const fee = row.original.fee;

          if (!fee) return '-';

          const amount = formatCurrency(fee / 100, currency);

          const fw = !row.parentId ? 500 : undefined;

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Text td={td} fw={fw}>
              {amount}
            </Text>
          );
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency((preview?.totals.fee ?? 0) / 100, currency)}
          </Text>
        ),
      },
      {
        header: 'Total',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableHeadCellProps: {
          align: 'right',
          pr: 'xs',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.amount || 0) / 100,
            currency
          );

          const fw = !row.parentId ? 500 : undefined;

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Text td={td} fw={fw}>
              {amount}
            </Text>
          );
        },
        AggregatedCell: ({ row }) => {
          return (
            <Text fw={500}>
              {formatCurrency(row.original.amount / 100, currency)}
            </Text>
          );
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency((preview?.totals.total ?? 0) / 100, currency)}
          </Text>
        ),
      },
    ],
    [currency, setFilter, preview?.totals.fee, preview?.totals.total]
  );

  return (
    <Box pos="relative">
      <Group
        mb="md"
        justify="space-between"
        wrap="nowrap"
        sx={(theme) => ({
          padding: theme.spacing.xs,
          backgroundColor: theme.colors.yellow[0],
          borderRadius: theme.radius.md,
        })}
      >
        <Title />
        <Text fw={500} ta="right">
          {formatCurrency((preview?.totals.fee ?? 0) / 100, currency)}
        </Text>
      </Group>

      <Collapse
        title={
          <Text component="span" size="sm">
            Preview
          </Text>
        }
        rightSection={null}
        minHeight={30}
        defaultOpened
      >
        <Box>
          <LoadingOverlay visible={isFetching && !isInitialLoading} />
          <LazyTable
            table={{
              columns,
              hideHeader: true,
              hideTopBar: true,
              emptyRowsFallback: () => (
                <Center>
                  <Text size="sm" c="gray">
                    No lines found
                  </Text>
                </Center>
              ),
              // onRowClick: {
              //   disabled: (row) => row.original.accounts?.length !== 1,
              //   handler: (row) => {
              //     const account = props.accounts.find((x) =>
              //       row.original.accounts.map((y) => y.id).includes(x.id)
              //     );

              //     if (account) setFilter({ search: account.title });
              //   },
              // },
            }}
            subRows={{
              getRowCanExpand: (row) => !!row.original.subRows?.length,
              defaultExpanded: true,
              getSubRows: (row) => row.subRows ?? [],
            }}
            data={{
              rows: lines,
              rowCount: lines.length,
              error: null,
              loading: isInitialLoading,
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

const Title = () => {
  const methods = useFeeForm();
  const title = useWatch({
    control: methods.control,
    name: 'title',
  });

  return (
    <Text ta="left" fw={500}>
      {title}
    </Text>
  );
};
