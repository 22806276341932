import { useUser } from '@clerk/clerk-react';
import { StringParam, useQueryParam } from '@finalytic/ui';
import { day, isUUID } from '@finalytic/utils';
import { useLocalStorage } from '@mantine/hooks';
import * as Sentry from '@sentry/react';
import type { HyperlineEntities } from '@vrplatform/hyperline-client';
import type { TenantType, UserRole } from '@vrplatform/log';
import { VRP_TENANT_ID, formatUserName } from '@vrplatform/ui-common';
import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router';
import { useQuery } from '../graphql';
import { useTrpcQuery } from '../trpc';
import { useExtension } from './useExtension';
import { useIntercom } from './useIntercom';
import { useBrowserTracking } from './useTracking';

const isLocalhost =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1');

function _useLocalStorageTeamId(userId: string | undefined) {
  return useLocalStorage<string | undefined>({
    key: `${userId}_tid`,
    defaultValue: undefined,
  });
}

function __useMe(args: {
  userId?: string;
  realUserId?: string;
  activeTeamId?: string;
  role?: string;
  imageUrl?: string;
}) {
  const [sti, setSti] = useQueryParam('sti', StringParam);
  const [, setTeamId] = _useLocalStorageTeamId(args.userId);
  const { sendMessage } = useExtension();

  const { group, identify, reset } = useBrowserTracking();
  const { boot, shutdown } = useIntercom();

  const { isLoading: loadingSti, data: stiData } = useQuery(
    (q, args) => {
      if (!args.sti || !isUUID(args.sti)) return null;

      return q
        .tenant({
          where: {
            id: {
              _eq: args.sti,
            },
          },
          limit: 1,
        })
        .map((t) => ({
          id: t.id,
        }))[0];
    },
    {
      skip: !sti,
      variables: {
        sti,
      },
    }
  );

  const {
    data: user,
    isLoading: loadingUser,
    error: errorUser,
    refetch: refetchUser,
  } = useQuery(
    (q, { userId, VRP_TENANT_ID }) => {
      if (!userId) return null;

      return (
        q
          .user({
            where: {
              id: { _eq: userId },
            },
            limit: 1,
          })
          .map((user) => {
            const partnerMemberships = user
              ?.memberships({
                where: {
                  _or: [
                    {
                      tenant: {
                        // Partner admin
                        type: { _eq: 'partner' },
                      },
                      role: { _eq: 'admin' },
                      status: { _eq: 'active' },
                    },
                    {
                      // VRP admin
                      tenantId: { _eq: VRP_TENANT_ID },
                      status: { _eq: 'active' },
                    },
                  ],
                },
              })
              .map((membership) => ({
                status: membership?.status,
                id: membership?.tenantId,
                role: membership?.role,
                teamType: membership?.tenant?.type,
              }));

            const isSuperAdmin = user.isAdmin;

            const isVrpAdmin =
              isSuperAdmin ||
              partnerMemberships.some((x) => x.id === VRP_TENANT_ID);

            const isPartnerAdmin =
              isSuperAdmin ||
              isVrpAdmin ||
              partnerMemberships.find((x) => x.teamType === 'partner')?.role ===
                'admin';

            return {
              id: user.id!,
              type: user.type!,
              name: user.name!,
              firstName: user.firstName,
              lastName: user.lastName,
              partnerId: user.partnerId,
              isSuperAdmin,
              isPartnerAdmin,
              isVrpAdmin,
              email: user.email!,
              createdAt: user.createdAt!,
              featureApprovals: user.featureApprovals().map((approval) => ({
                id: approval.id,
                status: approval.status,
                featureId: approval.featureId,
              })),
            };
          })[0] || null
      );
    },
    {
      skip: !args.userId,
      keepPreviousData: true,
      variables: {
        userId: args.userId,
        VRP_TENANT_ID: VRP_TENANT_ID,
      },
      queryKey: ['users', 'featureApprovals'],
    }
  );

  const {
    data: team,
    isLoading: loadingTeam,
    error: errorTeam,
    refetch: refetchTeam,
    isFetching: isFetchingTeam,
  } = useQuery(
    (q, { userId, activeTeamId }) => {
      if (!userId) return null;

      return (
        q
          .tenant({
            limit: 1,
            where: activeTeamId ? { id: { _eq: activeTeamId } } : undefined,
          })
          .map((item) => {
            const membership = item
              .members({ where: { userId: { _eq: userId } } })
              .map((x) => ({ id: x.id, role: x.role, status: x.status }))[0];

            const finalyticConnectionId = item
              .connections({
                where: {
                  appId: { _eq: 'finalytic' },
                },
                limit: 1,
                order_by: [{ appId: 'asc_nulls_first' }],
              })
              .map((connection) => connection.id)[0] as string;

            return {
              role: membership?.role,
              membershipId: membership?.id,
              membershipStatus: membership?.status,
              id: item.id!,
              subscriptionCancelledAt: item.cancelledAt,
              logo: item.logo!,
              name: item.name!,
              billingSubscriptionStatus:
                item.billingSubscriptionStatus as HyperlineEntities['Subscription']['status'],
              billingCustomerId: item.billingCustomerId,
              status: item.status! as 'active' | 'inactive',
              isOnboarding: !!item.isOnboarding,
              createdAt: item.createdAt!,
              colorPrimary: item.colorPrimary!,
              partnerId: item.partnerId!,
              billingPartnerId: item.calculatedBillingPartnerId,
              partnerName: item.partner?.name,
              enabledFeatures: item.enabledFeatures()?.map((x) => ({
                featureId: x.featureId,
                status: x.status,
                updatedAt: x.updatedAt,
              })),
              forceTwoFactorAuth: !!item
                .settings({
                  where: {
                    key: { _eq: 'tenantSettings' },
                    target: { _eq: 'forceTwoFactorAuth' },
                  },
                  limit: 1,
                })
                ?.map((x) => x.id)[0],
              type: item.type,
              finalyticConnectionId,
              // automations,
            };
          })[0] ?? null
      );
    },
    {
      skip: !args.userId,
      variables: {
        userId: args.userId,
        activeTeamId: stiData?.id || args.activeTeamId,
      },
      keepPreviousData: true,
      queryKey: ['teams', 'featureApprovals'],
    }
  );

  const isTeamAdmin = useMemo(
    () =>
      user?.isSuperAdmin ||
      user?.isVrpAdmin ||
      user?.isPartnerAdmin ||
      team?.role === 'admin',
    [user?.isSuperAdmin, user?.isVrpAdmin, user?.isPartnerAdmin, team?.role]
  );

  const canAccessTeam = useMemo(
    () =>
      isTeamAdmin ||
      user?.isPartnerAdmin ||
      user?.isVrpAdmin ||
      user?.isSuperAdmin ||
      !!team?.membershipId,
    [
      isTeamAdmin,
      user?.isPartnerAdmin,
      user?.isVrpAdmin,
      user?.isSuperAdmin,
      team?.membershipId,
    ]
  );

  const { data } = useTrpcQuery(
    'fetchIntercomHash',
    {
      tenantId: team?.id!,
    },
    {
      skip: !team?.id || !user?.id,
    }
  );

  const intercomIdentifyId = data?.intercom_user_hash;

  // Intercom & Sentry
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!user || !intercomIdentifyId) return () => {};

    boot?.({
      email: user.email,
      user_hash: intercomIdentifyId,
      user_id: user.id,
      name: formatUserName(
        {
          firstName: user.firstName,
          lastName: user.lastName,
        },
        { lastNameFirst: false, showEmpty: true }
      ),
      // messenger attributes
      custom_launcher_selector: '#intercom-widget',
      hide_default_launcher: !team?.isOnboarding || isLocalhost,
      alignment: 'right',
      horizontal_padding: 40,
      vertical_padding: 40,
    });

    Sentry.setUser({
      name: user.name,
      email: user.email,
      createdAt: user.createdAt,
      id: user.id,
    });

    return () => {
      shutdown?.();
      Sentry.setUser(null);
    };
  }, [user?.id, team?.isOnboarding, intercomIdentifyId]);

  // Tracking
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (user?.id) {
      const teamRole = {
        role: team?.role,
        isSuperAdmin: user?.isSuperAdmin ?? false,
        isVrpAdmin: user?.isVrpAdmin ?? false,
        isPartnerAdmin: user?.isPartnerAdmin ?? false,
        isTeamAdmin: isTeamAdmin,
      };

      identify(user.id, {
        email: user.email,
        avatar: args.imageUrl,
        name:
          user.firstName && user.lastName
            ? `${user.firstName} ${user.lastName}`
            : user.lastName,
        firstName: user.firstName,
        lastName: user.lastName,
        createdAt: day(user.createdAt).toISOString(),
        ...teamRole,
        role: (teamRole.isSuperAdmin
          ? 'super-admin'
          : teamRole.isVrpAdmin
            ? 'vrp-admin'
            : teamRole.isPartnerAdmin
              ? 'partner-admin'
              : teamRole.isTeamAdmin
                ? 'team-admin'
                : teamRole.role) as UserRole,
      });

      if (team) {
        group(team.id, {
          name: team.name,
          type: team.type as TenantType,
          avatar: team.logo,
          partner: team.partnerName,
          partnerId: team.partnerId,
          subscriptionStatus: team?.billingSubscriptionStatus,
          billingCustomerId: team.billingCustomerId,
          createdAt: day(team.createdAt).toISOString(), // like 2012-12-02T00:31:38.208Z
        });
      }
    }

    return () => {
      reset?.();
    };
  }, [user, team, group, identify, isTeamAdmin, args.imageUrl]);

  useEffect(() => {
    Sentry?.setTags({
      tenant_id: team?.id || null,
      tenant_name: team?.name || null,
    });
  }, [team?.id, team?.name]);

  useEffect(() => {
    if (stiData?.id && team?.id) {
      if (stiData.id === team.id) {
        setSti(undefined);
        setTeamId(stiData.id);
        sendMessage({ message: 'team_changed', data: { teamId: stiData.id } });
      }
    }
  }, [stiData, team?.id, setSti, setTeamId, sendMessage]);

  return {
    user: user
      ? { ...user, realUserId: args.realUserId, canAccessTeam, isTeamAdmin }
      : undefined,
    team,
    loading: loadingUser || loadingSti || loadingTeam,
    error: errorUser || errorTeam,
    isFetchingTeam,
    refetch: () => {
      refetchUser();
      refetchTeam();
    },
  };
}

type User = NonNullable<ReturnType<typeof __useMe>['user']>;
type Team = NonNullable<ReturnType<typeof __useMe>['team']>;

export const userContext = createContext<User>(undefined as any);
export const teamIdContext = createContext<{
  teamId: string;
  setTeamId: (value: string | null) => void;
}>({
  teamId: '',
  setTeamId: () => undefined,
});
export const teamContext = createContext<{
  teamId: string;
  setTeamId: (value: string) => void;
  team: Team;
  role: 'admin' | 'owner' | 'user';
  isTeamAdmin: boolean;
  isPartnerAdmin: boolean;
  isVrpAdmin: boolean;
  isSuperAdmin: boolean;
  isFetchingTeam: boolean;
  refetchTeam: () => Promise<void>;
}>({
  isTeamAdmin: false,
  isPartnerAdmin: false,
  isVrpAdmin: false,
  isSuperAdmin: false,
  role: 'user',
  team: {} as any,
  teamId: '',
  isFetchingTeam: false,
  setTeamId: () => undefined,
  refetchTeam: async () => undefined,
});

export function DBUserProvider({ children }: { children: ReactNode }) {
  const { user: u } = useUser();

  // const { ownerPreview } = useOwnerPreviewId();
  const realUserId = `${u?.publicMetadata.user_id || ''}`;
  // const ownerPreviewUserId = ownerPreview?.userId?.trim() || '';
  // const ownerPreviewTeamId = ownerPreview?.teamId?.trim() || '';

  const userId = `${u?.publicMetadata.user_id || ''}` || undefined;
  const [_teamId, setTeamId] = _useLocalStorageTeamId(userId);

  const { user, refetch, team, isFetchingTeam } = __useMe({
    userId,
    activeTeamId: _teamId,
    role: `${u?.publicMetadata.role || ''}`,
    realUserId,
    imageUrl: u?.imageUrl,
  });

  // For webextension
  useEffect(() => {
    if (u?.id) localStorage.setItem('cid', u.id);
    else localStorage.removeItem('cid');
    if (userId) localStorage.setItem('uid', userId);
    else localStorage.removeItem('uid');

    const tid = _teamId || team?.id;
    if (tid) localStorage.setItem('tid', tid);
    else localStorage.removeItem('tid');
  }, [userId, u?.id, _teamId, team?.id]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  const teamIdValue = useMemo(() => {
    return {
      teamId: _teamId || team?.id,
      setTeamId,
    };
  }, [_teamId || team?.id, setTeamId]);

  const teamValue = useMemo(() => {
    return {
      teamId: team?.id,
      setTeamId,
      team: team || {},
      role: team?.role,
      isSuperAdmin: user?.isSuperAdmin ?? false,
      isVrpAdmin: user?.isVrpAdmin ?? false,
      isPartnerAdmin: user?.isPartnerAdmin ?? false,
      isTeamAdmin: user?.isTeamAdmin ?? false,
      refetchTeam: refetch,
      isFetchingTeam,
    };
  }, [
    team,
    setTeamId,
    refetch,
    user?.isSuperAdmin,
    user?.isVrpAdmin,
    user?.isPartnerAdmin,
    user?.isTeamAdmin,
    isFetchingTeam,
  ]);

  return (
    <userContext.Provider value={user!}>
      <teamIdContext.Provider value={teamIdValue as any}>
        <teamContext.Provider value={teamValue as any}>
          {children}
        </teamContext.Provider>
      </teamIdContext.Provider>
    </userContext.Provider>
  );
}

export function useMe() {
  let user = useContext(userContext);
  if (!user) user = {} as any;
  return user;
}

export function useTeamId() {
  const { sendMessage } = useExtension();
  const goto = useNavigate();

  let tenant = useContext(teamIdContext);
  if (!tenant) tenant = [undefined, () => undefined] as any;

  // biome-ignore lint/suspicious/useAwait: <explanation>
  const setTeamId = async (teamId: string | null) => {
    tenant.setTeamId(teamId);

    const isLinkSharing = window.location.search?.includes('sti=');

    if (!isLinkSharing) {
      if (window.location.pathname.includes('statement')) {
        goto('/statements');
      } else if (window.location.pathname.includes('/reconciliation')) {
        goto('/reconciliation');
      } else if (window.location.pathname.includes('/deposit')) {
        goto('/deposits');
      } else if (window.location.pathname.includes('/expense')) {
        goto('/expenses');
      } else if (window.location.pathname.includes('/listing')) {
        goto('/listings');
      } else if (window.location.pathname.startsWith('/fees-commission')) {
        goto('/fees-commissions');
      }
    }

    try {
      sendMessage({ message: 'team_changed', data: { teamId } });
    } catch (error) {
      console.log(error);
    }
  };

  return [tenant.teamId, setTeamId] as [string, (value: string | null) => void];
}

export function useTeam() {
  const tenant = useContext(teamContext);

  return useMemo(
    () => [tenant.team, tenant.refetchTeam, tenant.isFetchingTeam] as const,
    [tenant.team, tenant.refetchTeam, tenant.isFetchingTeam]
  );
}

export function useTeamRole() {
  const tenant = useContext(teamContext);

  return {
    teamRole: tenant.role,
    isTeamAdmin: tenant.isTeamAdmin,
    isPartnerAdmin: tenant.isPartnerAdmin,
    isSuperAdmin: tenant.isSuperAdmin,
    isVrpAdmin: tenant.isVrpAdmin,
  };
}
