import type { listingOwnershipPeriod_bool_exp } from '@finalytic/graphql';
import { day, ensure } from '@finalytic/utils';
import { z } from 'zod';
import { whereListings } from './whereListings';

export const ownerStatementsGLFilterInput = {
  status: z
    .enum(['draft', 'inReview', 'void', 'published', 'posted'])
    .nullish(),
  search: z.string().nullish(),
  ownerId: z.string().nullish(),
  listingId: z.string().nullish(),
  periodId: z.string().nullish(),
  // listingGroup: z.string().nullish(),
  month: z.string().nullish(),
};

const ownerStatementsFilter = z.object(ownerStatementsGLFilterInput);

export type OwnerStatementsGLFilterParams = z.infer<
  typeof ownerStatementsFilter
>;

type BaseWhere = {
  // dashboard: Dashboard;
  tenantId: string;
  meId: string;
};

export function whereOwnerStatementsGL({
  search,
  tenantId,
  month,
  listingId,
  periodId,
  meId,
}: BaseWhere & OwnerStatementsGLFilterParams) {
  return ensure<listingOwnershipPeriod_bool_exp>({
    tenantId: { _eq: tenantId },
    journalEntries: {
      // ownerStatementId: { _is_null: true },
      txnAt: month ? { _lte: day(month).endOf('month').yyyymmdd() } : undefined,
    },
    members: {},
    id: periodId ? { _eq: periodId } : undefined,
    listingId: listingId ? { _eq: listingId } : undefined,
    listing: search
      ? whereListings({
          search,
          tenantId,
          dashboard: 'propertyManager',
          meId,
          GL: true,
        })
      : undefined,
    _or:
      !month || periodId
        ? undefined
        : [
            {
              startAt: { _lte: month },
              endAt: { _gte: month },
            },
            {
              startAt: { _lte: month },
              endAt: { _is_null: true },
            },
            {
              startAt: {
                _gte: month,
                _lte: day(month).endOf('month').yyyymmdd(),
              },
            },
          ],
  });
}
