import { useAuth } from '@clerk/clerk-react';
import { day, hasValue } from '@finalytic/utils';
import * as Sentry from '@sentry/react';
import {
  type GroupProps,
  type IdentifyProps,
  type TrackingEvent as LogTrackingEvent,
  type TrackingEventProps,
  isTest,
} from '@vrplatform/log';
import { formatUserName } from '@vrplatform/ui-common';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';
import { GRAPHQL_URI } from '../env';
import { useGqtyClient, useMutation } from '../graphql';
import { captureSentryError } from './sentry';
import { useMe, useTeam } from './team';
import { Intercom, useIntercom } from './useIntercom';

export type Tracking = ReturnType<typeof useBrowserTracking>;
export type TrackingEvent = LogTrackingEvent;

const isLocalhost =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1');
const isStaging = window.location.hostname === 'staging.portal.vrplatform.app';
const isProduction = window.location.hostname === 'portal.vrplatform.app';
const isExtension = window.location.href.startsWith('chrome-extension');
const enableTracking = !isLocalhost && !isExtension;

export function useTrackingInit() {
  return useMemo(() => {
    if (!enableTracking) return;

    // INTERCOM
    Intercom({
      custom_launcher_selector: '#intercom-widget',
      alignment: 'right',
      horizontal_padding: 40,
      vertical_padding: 40,
    });

    // SENTRY
    Sentry.init({
      dsn: 'https://4ba9b6d2701d4a889e6f323c050a4a46@o515442.ingest.sentry.io/5624987',
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 1.0,
      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.DEV
        ? 'development'
        : isProduction
          ? 'production'
          : 'staging',
      tracesSampleRate: 0.1,
      tunnel: 'https://pomegranate.vrplatform.app',
      tracePropagationTargets: [
        'localhost:8877',
        'api.vrplatform.app',
        window.location.host,
      ],
      integrations: [
        ...Sentry.getDefaultIntegrations({}),
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllInputs: false,
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [
            GRAPHQL_URI,
            'https://clerk.vrplatform.app/v1/client/.*',
            'https://cdn.segment.com/v1/projects.*',
            'https://api.vrplatform.app/.*',
            'https://localhost:8877/.*',
            `${window.location.origin}/trpc/.*`,
          ],
          networkCaptureBodies: true,
          networkResponseHeaders: ['X-Request-Id'],
          networkRequestHeaders: ['Finalytic-Platform'],
        }),
        isStaging
          ? Sentry.feedbackIntegration({
              colorScheme: 'light',
              showEmail: false,
              showName: false,
              showBranding: false,
              autoInject: false,
              themeLight: {
                submitBackground: '#025FCA',
                submitBackgroundHover: '#024797',
                submitBorder: '#025FCA',
                submitOutlineFocus: '#025FCA',
              },
            })
          : undefined,
      ].filter(hasValue),
    });

    // MIXPANEL
    mixpanel.init('933a19cf6d01a51a06ccdd083083f2a9', {
      api_host: 'https://api-eu.mixpanel.com',
      // persistence: 'localStorage',
      record_sessions_percent: 1, //records 1% of all sessions
    });
  }, []);
}

export function useBrowserTracking() {
  const { trackEvent, update, shutdown } = useIntercom();
  const [{ id: tenantId }] = useTeam();
  const { actor } = useAuth();
  const {
    id: userId,
    email: userEmail,
    firstName,
    lastName,
    createdAt,
  } = useMe();
  const gqt = useGqtyClient();

  const { mutate } = useMutation(
    (
      q,
      args: {
        event: string;
        tenantId: string;
        userId: string;
        properties?: Record<string, any>;
      }
    ) =>
      q.insert_tracking_event_one({
        object: {
          name: args.event,
          tenantId: args.tenantId,
          userId: args.userId,
          props: args.properties,
          packageName: 'ui',
        },
      })?.id,
    {
      hideNotification: true,
    }
  );

  if (!enableTracking || isTest(userId, tenantId, userEmail) || !!actor)
    return {
      track: async () => {},
      identify: () => {},
      group: () => {},
      page: () => {},
      reset: () => {},
    };

  return {
    track: useCallback(
      async <TEvent extends LogTrackingEvent>(
        event: TEvent,
        data: {
          userId?: string;
          tenantId?: string;
          userEmail?: string;
        } & TrackingEventProps[TEvent]
      ) => {
        const meta = { userId, tenantId, userEmail, ...data };

        if (isTest(meta.userId, meta.tenantId, meta.userEmail)) return;

        let id: string | undefined;
        if (gqt)
          id = await mutate({
            args: {
              event,
              tenantId: meta.tenantId,
              userId: meta.userId,
              properties: meta,
            },
          }).catch((err) => {
            captureSentryError(err);

            return undefined;
          });
        else
          console.warn(
            `GQTY client not found. Tracking event ${event} will not work!`,
            meta
          );

        mixpanel.track(event, {
          $insert_id: id,
          $distinct_id: userId,
          tenant: [meta.tenantId], // assign to group
          ...meta,
        });

        // INTERCOM
        trackEvent?.(event, meta);
      },
      [userId, tenantId, userEmail, gqt, mutate, trackEvent]
    ),
    identify: useCallback(
      (
        userId: string,
        {
          email,
          avatar,
          firstName: _firstName,
          lastName: _lastName,
          createdAt: _createdAt,
          lastSeenAt: _lastSeenAt,
          ...traits
        }: IdentifyProps['traits'] = {}
      ) => {
        const name = formatUserName(
          {
            firstName: _firstName || firstName,
            lastName: _lastName || lastName,
          },
          { lastNameFirst: false, showEmpty: true }
        );
        const __createdAt =
          _createdAt || createdAt
            ? day(_createdAt || createdAt).unix()
            : undefined;
        const lastSeenAt = _lastSeenAt ? day(_lastSeenAt).unix() : day().unix();

        mixpanel.identify(userId);
        mixpanel.people.set({
          $distinct_id: userId,
          $name: name,
          $first_name: firstName,
          $last_name: lastName,
          $email: email || userEmail,
          $avatar: avatar,
          $last_seen: lastSeenAt,
          $created: __createdAt,

          ...traits,
        });

        // INTERCOM
        update?.({
          user_id: userId,
          email: email || userEmail,
          name,
          created_at: __createdAt,
          last_request_at: day().unix(),
          last_seen_at: lastSeenAt,
          avatar,
          // custom properties
          userType: traits?.role,
        });
      },
      [createdAt, firstName, lastName, userEmail, update]
    ),
    group: useCallback(
      (groupId: string, traits: GroupProps['traits'] = {}) => {
        // add teamId to traits
        if (!traits?.teamId) traits.teamId = groupId;

        const cid = traits?.billingCustomerId || traits?.hyperlineCustomerId;
        const billingPartnerName =
          traits?.billingPartner ||
          traits?.partner ||
          traits?.accountingPartner;

        mixpanel.set_group('tenant', groupId);
        mixpanel.get_group('tenant', groupId).set({
          $distinct_id: groupId,
          $name: traits?.name,
          // $avatar: tenant.logo,
          $created: traits?.createdAt
            ? new Date(traits.createdAt).getTime() / 1000
            : undefined,

          plan: traits?.plan,
          mrr: traits?.mrr,
          type: traits?.type,
          status: traits?.status,
          pms: traits?.pms,
          accountingSoftware: traits?.accountingSoftware,
          activeListingCount: traits?.activeListings,
          paymentMethodType: traits?.paymentMethodType,
          partnerName: traits?.partner || traits?.accountingPartner,
          partnerId: traits?.partnerId || traits?.accountingPartnerId,
          billingPortalUrl: cid
            ? `https://billing.vrplatform.app/portal/${cid}`
            : undefined,
          billingSubscriptionStatus: traits?.billingSubscriptionStatus,
          billingPartnerName,
          billingCustomerId: cid,
          trialUntil: traits?.trialUntil,
          isOnboarding: traits?.isOnboarding,
          isTest: billingPartnerName
            ? billingPartnerName === 'Demo and Test Console'
            : undefined,
        });

        // INTERCOM
        update?.({
          user_id: userId,
          company: {
            company_id: groupId,
            name: traits?.name,
            created_at: traits?.createdAt
              ? new Date(traits.createdAt).getTime() / 1000
              : undefined,
            plan: traits?.plan,
            monthly_spend: traits?.mrr,
            // custom properties
            teamId: groupId,
            team: traits?.name,
            type: traits?.type,
            status: traits?.status,
            pms: traits?.pms,
            accountingSoftware: traits?.accountingSoftware,
            activeListings: traits?.activeListings,
            paymentMethodType: traits?.paymentMethodType,
            partnerName: traits?.partner || traits?.accountingPartner,
            billingPortalUrl: cid
              ? `https://billing.vrplatform.app/portal/${cid}`
              : undefined,
            billingStatus: traits?.billingSubscriptionStatus,
            billingPartner:
              traits?.billingPartner ||
              traits?.partner ||
              traits?.accountingPartner,
          },
        });
      },
      [userId, update]
    ),
    page: useCallback(
      (_?: string, name?: string) => {
        mixpanel.track_pageview();

        // INTERCOM
        update?.({ user_id: userId, page_title: name });
      },
      [userId, update]
    ),
    reset: useCallback(() => {
      mixpanel.reset();

      // INTERCOM
      shutdown?.();
    }, [shutdown]),
  };
}
