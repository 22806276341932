import { ConfirmModal, RenameModal } from '@finalytic/components';
import {
  useApiMutation,
  useInvalidateQueries,
  useMutation,
} from '@finalytic/data';
import {
  showErrorNotification,
  showLoadingNotification,
  updateErrorNotification,
  updateSuccessNotification,
} from '@finalytic/ui';
import type { Maybe } from '@finalytic/utils';
import { connectionHiddenStatusDefault } from '@vrplatform/ui-common';
import type { ConnectionRow } from './useConnectionTableQuery';

export const ConnectionEllipsisMenuModals = ({
  data,
  deleteModal,
  renameModal,
  updateModal,
}: {
  data: Maybe<ConnectionRow>;
  deleteModal: {
    opened: boolean;
    close: () => void;
  };
  updateModal: {
    opened: boolean;
    close: () => void;
  };
  renameModal: {
    opened: boolean;
    close: () => void;
  };
}) => {
  const id = data?.id;

  const notificationId = 'delete-connection';

  const invalidate = useInvalidateQueries(['connections']);

  const { mutateAsync: deleteConnection, isPending: loadingDeleteConnection } =
    useApiMutation('delete', '/connections/{id}');

  const connectionStatus = data?.connectionStatus || 'active';
  const connectionName = data?.name;
  const isArchived = connectionHiddenStatusDefault.includes(
    connectionStatus.toLowerCase()
  );

  const handleConnectionDeletion = async () => {
    if (!id) return { isSuccess: false, message: 'Connection ID is missing.' };

    try {
      showLoadingNotification({
        id: notificationId,
      });

      const res = await deleteConnection({
        params: {
          path: {
            id,
          },
          query: {
            onLocked: 'archive',
          },
        },
      });

      if (res.status === 'archived') {
        updateSuccessNotification({
          id: notificationId,
          title: 'Connection was archived.',
          message:
            'The connection could not be deleted and not was archived instead. Please make sure that no features on your team are still using data or mappings from this connection and try again.',
        });
      } else {
        updateSuccessNotification({
          id: notificationId,
          title: 'Success!',
          message: 'Connection deleted successfully.',
        });
      }

      invalidate();
      deleteModal.close();
    } catch (error: any) {
      const message = error?.message || 'Failed to delete connection.';

      updateErrorNotification({
        id: notificationId,
        title: 'Failed to delete connection',
        message,
      });
    }
  };

  const {
    mutate: updateConnectionStatus,
    loading: loadingUpdateConnectionStatus,
  } = useMutation(
    (
      q,
      args: { id: string; status: 'active' | 'archived'; tenantId: string }
    ) => {
      const connection = q.updateConnectionById({
        pk_columns: { id: args.id },
        _set: {
          status: args.status,
        },
      });

      if (args.status === 'archived') {
        q.updateAutomations({
          where: {
            tenantId: { _eq: args.tenantId },
            _or: [
              {
                leftConnectionId: { _eq: args.id },
              },
              {
                rightConnectionId: { _eq: args.id },
              },
            ],
          },
          _set: {
            status: 'inactive',
          },
        })?.affected_rows || 0;
      }

      return {
        ok: !!connection?.id,
      };
    },
    {
      successMessage: {
        id: `update-connection-${data?.id || ''}`,
        title: 'Success!',
        message: 'Successfully updated connection.',
      },
      invalidateQueryKeys: ['connections', 'scheduledEvents'],
    }
  );

  const handleConnectionArchive = async () => {
    if (!id) return { isSuccess: false, message: 'Connection ID is missing.' };
    if (!data.tenant?.id)
      return { isSuccess: false, message: 'Tenant ID is missing.' };

    try {
      const res = await updateConnectionStatus({
        args: {
          id,
          status: isArchived ? 'active' : 'archived',
          tenantId: data?.tenant.id,
        },
      });
      if (!res?.ok) throw new Error('Connection update failed.');
      invalidate();
      updateModal.close();
    } catch (error: any) {
      console.error(error);
      return {
        isSuccess: false,
        message: error?.message || 'Failed to update connection.',
      };
    }
  };

  const { mutate: updateConnectionTitle } = useMutation(
    (q, args: { id: string; title: string }) => {
      const connection = q.updateConnectionById({
        pk_columns: { id: args.id },
        _set: {
          name: args.title,
        },
      });

      return {
        ok: !!connection?.id,
      };
    },
    {
      successMessage: {
        id: `update-connection-${data?.id || ''}`,
        title: 'Success!',
        message: 'Successfully updated connection.',
      },
      invalidateQueryKeys: ['connections', 'scheduledEvents'],
    }
  );

  return (
    <>
      <ConfirmModal
        type="delete"
        opened={deleteModal.opened}
        closeModal={deleteModal.close}
        onSubmit={handleConnectionDeletion}
        title={`Are you sure you want to delete this connection: ${connectionName}?`}
        subtitle="All connected data will be lost."
        loading={loadingDeleteConnection}
      />
      <ConfirmModal
        type="confirm"
        loading={loadingUpdateConnectionStatus}
        opened={updateModal.opened}
        closeModal={updateModal.close}
        onSubmit={handleConnectionArchive}
        title={isArchived ? 'Enable connection' : 'Archive connection'}
        subtitle={
          isArchived
            ? `Would you like to re-enable ${connectionName || 'this connection'}? The system will perform scheduled fetches on this connection again.`
            : `Are you sure you want to archive ${
                connectionName || 'this connection'
              }? This will disable all automations connected to this connection.`
        }
        submitTitle={isArchived ? 'Enable' : 'Archive'}
        icon={isArchived ? 'RefreshCwIcon' : 'ArchiveIcon'}
      />
      <RenameModal
        opened={renameModal.opened}
        onClose={renameModal.close}
        title="Edit Connection Name"
        inputTitle="Name"
        inputPlaceholder="Enter your connection name"
        defaultValue={connectionName}
        onSubmit={async (value) => {
          if (!id)
            return showErrorNotification({
              color: 'yellow',
              message: 'Connection ID is missing.',
              title: 'Missing input',
            });

          try {
            await updateConnectionTitle({
              args: {
                id,
                title: value,
              },
            });
            invalidate();
            renameModal.close();
          } catch (error) {
            console.log(error);
          }
        }}
      />
    </>
  );
};
