import { StringParam, useQueryParam } from '@finalytic/ui';
import { Box, Skeleton, Stack } from '@mantine/core';
import { LoadingOverlay, useCombobox } from '@mantine/core';
import { useState } from 'react';
import { LedgerReservationInfoCard } from '../../../../../drawers/reservation-drawers/LedgerReservationDetailDrawer';
import { FeeReservationPreviewTable } from './FeeReservationPreviewTable';
import {
  FeeReservationSelect,
  useFeeReservationDropdownQuery,
} from './FeeReservationSelect';
import { useFeeReservationPreviewQuery } from './useFeeReservationPreviewQuery';

export const FEE_RESERVATION_PREVIEW_SEARCH_KEY = 'fee-reservation-preview';

export const FeeReservationPreview = () => {
  const [search, setSearch] = useState('');

  const [selectedReservationId = null, setSelectedReservationId] =
    useQueryParam(FEE_RESERVATION_PREVIEW_SEARCH_KEY, StringParam);

  const { data: listData = [], isLoading: loadingList } =
    useFeeReservationDropdownQuery({
      search,
    });

  const { data: reservation, isLoading: loading } =
    useFeeReservationPreviewQuery({
      reservationId: selectedReservationId || (listData?.[0]?.value ?? null),
    });

  const combobox = useCombobox({
    onDropdownClose: () => {
      combobox.resetSelectedOption();
      combobox.focusTarget();
      setSearch('');
    },

    onDropdownOpen: () => {
      combobox.focusSearchInput();
    },
  });

  return (
    <Box
      p="lg"
      pos="relative"
      flex={1}
      sx={{
        overflow: 'scroll',
      }}
    >
      <LoadingOverlay visible={loading} />
      <FeeReservationSelect
        listData={listData}
        loadingList={loadingList}
        reservation={reservation ?? null}
        search={search}
        setSearch={setSearch}
        setSelectedReservationId={setSelectedReservationId}
      />
      <Stack mt="md">
        {!reservation ? (
          <>
            <Skeleton h={50} />
            <Skeleton h={50} />
            <Skeleton h={50} />
          </>
        ) : (
          <>
            <LedgerReservationInfoCard
              bookedAt={reservation.bookedAt}
              bookingPlatform={reservation.bookingPlatform}
              cancelledAt={reservation.cancelledAt}
              checkIn={reservation.checkIn}
              checkOut={reservation.checkOut}
              currency={reservation.currency}
              listing={
                reservation.listing?.id
                  ? {
                      id: reservation.listing.id,
                      name: reservation.listing.name,
                    }
                  : undefined
              }
              payment={{
                expected: reservation.total,
                received: reservation.paid,
                status: reservation.paidStatus ?? 'unpaid',
              }}
              status={
                reservation.status === 'cancelled'
                  ? 'canceled'
                  : reservation.status === 'booked'
                    ? 'booked'
                    : 'inactive'
              }
            />

            <FeeReservationPreviewTable reservation={reservation} />
          </>
        )}
      </Stack>
    </Box>
  );
};
