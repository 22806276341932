import { Badge, Button, IconButton } from '@finalytic/components';
import {
  useApiMutation,
  useGqtyClient,
  useInvalidateQueries,
  useMutation,
  useTeamId,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import type { currency_enum, party_enum } from '@finalytic/graphql';
import {
  CalendarDatesIcon,
  CalendarEventIcon,
  CreditCardIncomeIcon,
  CrossCircleIcon,
  Edit3Icon,
  HomeIcon,
  Icon,
  type IconDefinition,
  LoaderIcon,
  UserIcon,
} from '@finalytic/icons';
import type { MRT_ColumnDef } from '@finalytic/table';
import {
  Drawer,
  EllipsisMenuCopyItem,
  EllipsisMenuDivider,
  EllipsisMenuItem,
  StringParam,
  showErrorNotification,
  useQueryParamSet,
} from '@finalytic/ui';
import {
  type Maybe,
  formatCurrency,
  hasValue,
  sum,
  toTitleCase,
  uniqueBy,
  utc,
} from '@finalytic/utils';
import {
  Anchor,
  Avatar,
  Box,
  Card,
  Center,
  Group,
  HoverCard,
  LoadingOverlay,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  Title,
  Tooltip,
  rem,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import {
  type ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { ReservationStatusBadge } from '../../components';
import { ReservationPaymentStatusBadge } from '../../components/ReservationPaymentStatusBagde';
import { useExtractMutation } from '../../hooks';
import { useJournalEntriesModal } from '../../modals';
import { useAddExpenseNavigate } from '../../views/expenses/edit/useAddReservationExpense';
import { FEE_RESERVATION_PREVIEW_SEARCH_KEY } from '../../views/fees/edit/_components';
import { FEE_TYPES } from '../../views/fees/overview/_components';
import { getFeeTypeRouteFromRecurringFeeEnum } from '../../views/fees/overview/_utils';
import { useListingDetailDrawer } from '../../views/listings/drawer';
import {
  DrawerCollapsableTable,
  DrawerHeader,
  DrawerInfoCard,
} from '../_components';
import { useDepositDetailDrawer } from '../deposit-drawer';
import { useExpenseDetailDrawer } from '../expense-drawer';
import {
  ReservationAdjustmentAddModal,
  ReservationAdjustmentDeleteModal,
  ReservationEditForm,
  ReservationFeeAddModal,
  ReservationFeeDeleteModal,
} from './_components';
import { useHasReservationUserDataEditAccess } from './_hooks/useHasReservationUserDataEditAccess';
import {
  type Reservation,
  useLedgerReservationDetailDrawerQuery,
} from './_queries/useLedgerReservationDetailDrawerQuery';
import { useReservationDetailDrawer } from './useReservationDetailDrawer';

function useReservationUpdateMutation() {
  const invalidate = useInvalidateQueries(['reservations']);
  const { mutateAsync: mutate, isPending: loading } = useApiMutation(
    'put',
    '/reservations/{id}',
    {
      onSuccess: () => {
        invalidate();
      },
      onError: (error: any) => {
        console.error(error);
        showErrorNotification({
          title: 'Failed to update the reservation',
          message:
            error?.message ||
            'We failed to update the reservation. Please try again and contact support if the issue persists.',
        });
      },
    }
  );

  return {
    mutate,
    loading,
  };
}

export const LedgerReservationDetailDrawer = () => {
  const { colorScheme } = useMantineColorScheme();
  const { colors } = useMantineTheme();

  const { opened, close, reservationId, view, setView } =
    useReservationDetailDrawer();

  const { open: openJournalEntriesModal } = useJournalEntriesModal();
  const { loading, mutate } = useReservationUpdateMutation();
  const { mutate: extract, loading: loadingExtract } = useExtractMutation();

  const hasUserDataEditAccess = useHasReservationUserDataEditAccess();

  const { isLoading, data: reservation } =
    useLedgerReservationDetailDrawerQuery(reservationId);

  const updatePaymentData = useMutation(
    (
      q,
      { reservationId, userdata }: { reservationId: string; userdata: any }
    ) => {
      return q.updateReservation({
        pk_columns: {
          id: reservationId,
        },
        _set: {
          userdata,
        },
      })?.id;
    },
    {
      invalidateQueryKeys: ['reservations'],
    }
  );

  return (
    <Drawer opened={opened} onClose={close} size={550}>
      <DrawerHeader
        closeDrawer={close}
        title={
          reservation && (
            <Group mt={rem(5)} wrap="nowrap">
              <Avatar src={reservation.app?.iconRound} />
              <Box>
                <Text size="lg" fw={500}>
                  {reservation.guestName}
                </Text>
                <Text
                  size="xs"
                  c={colorScheme === 'dark' ? colors.gray[6] : 'gray'}
                  fw={400}
                >
                  {`${uniqueBy(
                    [
                      reservation?.confirmationCode,
                      reservation?.pmsReferenceCode,
                    ].filter((x) => x)
                  ).join(', ')}`}
                </Text>
              </Box>
            </Group>
          )
        }
        type="Reservation"
        loading={isLoading}
        menuItems={
          view === 'overview' &&
          reservation && (
            <>
              {hasUserDataEditAccess && (
                <EllipsisMenuItem
                  onClick={() => setView('edit')}
                  customIcon={<Edit3Icon size={16} />}
                >
                  Edit User Data
                </EllipsisMenuItem>
              )}

              {!!reservation.listingConnection?.id && (
                <EllipsisMenuItem
                  customIcon={<Icon icon={'RefreshCwIcon'} size={16} />}
                  loading={loadingExtract}
                  disabled={
                    !reservation.uniqueRef || !reservation.listingConnection?.id
                  }
                  onClick={() =>
                    extract({
                      connectionId: reservation.listingConnection!.id,
                      extractor: 'reservation',
                      forceUpdate: true,
                      params: {
                        uniqueRef: reservation.uniqueRef,
                        date: reservation.checkIn,
                      },
                    })
                  }
                >
                  Refetch
                </EllipsisMenuItem>
              )}

              <EllipsisMenuItem
                onClick={() =>
                  openJournalEntriesModal({
                    reservationId: { _eq: reservation.id },
                  })
                }
                customIcon={<Icon icon="ListUnorderedIcon" size={16} />}
              >
                Journal Entries
              </EllipsisMenuItem>

              <HiddenFeatureIndicator permission="vrp-admin">
                <EllipsisMenuDivider />

                <EllipsisMenuItem
                  loading={loading}
                  onClick={() =>
                    mutate({
                      params: {
                        path: {
                          id: reservation.id,
                        },
                      },
                      body: {
                        status:
                          reservation.status === 'canceled'
                            ? 'booked'
                            : 'cancelled',
                      },
                    })
                  }
                  customIcon={<Icon icon="AlertTriangleIcon" size={16} />}
                >
                  {reservation.status === 'canceled'
                    ? 'Revert cancellation'
                    : 'Cancel reservation'}
                </EllipsisMenuItem>

                <EllipsisMenuCopyItem value={reservation.id} />
              </HiddenFeatureIndicator>
            </>
          )
        }
      />
      {!reservation && !isLoading ? (
        'No reservation found'
      ) : view === 'edit' && reservation ? (
        <ReservationEditForm
          initialValues={{
            userData: JSON.stringify(reservation.userdata || {}, null, 2),
          }}
          onReset={() => setView('overview')}
          isLoading={isLoading}
          handleSubmit={async (values) => {
            updatePaymentData
              .mutate({
                args: {
                  reservationId: reservation.id,
                  userdata: JSON.parse(values.userData?.trim() || '{}'),
                },
              })
              .then(() => setView('overview'));
          }}
        />
      ) : (
        <Content reservation={reservation} isLoading={isLoading || loading} />
      )}
    </Drawer>
  );
};

const Content = ({
  reservation,
  isLoading,
}: { reservation: Maybe<Reservation>; isLoading: boolean }) => {
  const { edit, remove } = useAdjustmentModals();

  if (!reservation) return null;

  return (
    <Stack
      gap={'md'}
      mb="md"
      sx={{
        position: 'relative',
        flex: 1,
      }}
    >
      <ReservationAdjustmentAddModal
        closeModal={() => edit.setOpened(null)}
        reservation={{
          id: reservation.id,
          status: reservation.status,
        }}
        reservationLine={edit.opened}
      />
      <ReservationAdjustmentDeleteModal
        closeModal={() => remove.setOpened(null)}
        reservationLine={remove.opened}
        reservationId={reservation.id}
      />

      {reservation.isPriorToStartDate && <IsPriorToStartDate />}

      <LedgerReservationInfoCard {...reservation} />

      {/* Statistics */}
      {!reservation.isCancelledAndPending && <Statistics {...reservation} />}

      <Financials
        title="Financials"
        financials={reservation.financials}
        totals={reservation.totals}
        currency={reservation.currency}
        status={reservation.status}
        reservationId={reservation.id}
        isCancelledAndPending={reservation.isCancelledAndPending}
        payment={reservation.payment}
        openDeleteAdjustment={remove.setOpened}
        openEditAdjustment={edit.setOpened}
        lines={reservation?.lines}
      />

      <FeesAndCommissions
        title="Fees and Commissions"
        rowData={reservation.feesAndCommissions}
        currency={reservation.currency}
        reservationId={reservation.id}
      />

      {/* Payments */}
      <Deposits
        title="Payments / Refunds"
        currency={reservation.currency}
        deposits={reservation.deposits}
      />

      <Expenses
        title="Expenses"
        currency={reservation.currency}
        expenses={reservation.expenses}
        reservationId={reservation.id}
      />

      <LoadingOverlay
        visible={isLoading}
        loaderProps={{
          size: 'sm',
        }}
      />
    </Stack>
  );
};

function useAdjustmentModals() {
  const [opened, setOpened] = useState<{
    reservationId: string;
    lineType: string | null;
    description: string;
    centTotal: number | undefined;
    party: party_enum;
  } | null>(null);

  const [deleteModalReservationLine, setDeleteModalReservationLine] = useState<{
    id: string;
    reservationId: string;
  } | null>(null);

  return {
    edit: {
      opened,
      setOpened,
    },
    remove: {
      opened: deleteModalReservationLine,
      setOpened: setDeleteModalReservationLine,
    },
  };
}

export const LedgerReservationInfoCard = (reservation: {
  status: Reservation['status'];
  listing?: Reservation['listing'];
  bookedAt?: Reservation['bookedAt'];
  cancelledAt?: Reservation['cancelledAt'];
  checkIn?: Reservation['checkIn'];
  checkOut?: Reservation['checkOut'];
  userdata?: Reservation['userdata'];
  bookingPlatform?: Reservation['bookingPlatform'];
  currency?: Reservation['currency'];
  payment: Reservation['payment'];
}) => {
  const { open: openListingDetailDrawer } = useListingDetailDrawer();

  const userDataKeys = Object.keys(reservation?.userdata || {});

  const setView = useQueryParamSet('view', StringParam);

  return (
    <DrawerInfoCard
      mb={0}
      rows={[
        {
          icon: LoaderIcon,
          title: 'Status',
          text: <ReservationStatusBadge status={reservation.status} />,
        },
        {
          icon: HomeIcon,
          title: 'Listing',
          text: reservation.listing?.id ? (
            <Anchor
              onClick={() =>
                openListingDetailDrawer(
                  reservation.listing?.id!,
                  'overview',
                  'push'
                )
              }
            >
              {reservation.listing?.name ?? '-'}
            </Anchor>
          ) : null,
        },
        {
          icon: CalendarEventIcon,
          title: 'Booked',
          text: `${
            reservation.bookedAt
              ? utc(reservation.bookedAt).format('MMM DD, YYYY')
              : 'unknown date'
          }, ${toTitleCase(reservation.bookingPlatform || 'unknown channel')}`,
        },
        {
          icon: CrossCircleIcon,
          title: 'Cancelled At',
          text:
            reservation.status === 'canceled' && reservation.cancelledAt
              ? utc(reservation.cancelledAt).format('MMM DD, YYYY')
              : undefined,
        },
        {
          icon: CalendarDatesIcon,
          title: 'Dates',
          text: [reservation.checkIn, reservation.checkOut]
            .filter(hasValue)
            .map((x) => utc(x).format('MMM DD, YYYY'))
            .join(' - '),
        },
        {
          icon: UserIcon,
          title: 'User data',
          text: !!userDataKeys.length && (
            <Anchor onClick={() => setView('edit')}>
              {userDataKeys.length} updated key
              {userDataKeys.length > 1 && 's'}
            </Anchor>
          ),
        },

        {
          icon: CreditCardIncomeIcon,
          title: 'Balance',
          text: (
            <ReservationPaymentStatusBadge
              paidStatus={reservation.payment.status}
              paymentsCentTotal={reservation.payment.received}
              reservationCentTotal={reservation.payment.expected}
              currency={reservation.currency}
            />
          ),
        },
      ]}
    />
  );
};

const Statistics = (reservation: Reservation) => {
  const theme = useMantineTheme();

  // TOTALS
  const currency = reservation.currency;
  const total = reservation.totals?.total ?? 0;
  const pmRevenue = reservation.totals?.manager ?? 0;
  const taxes = reservation.totals?.tax ?? 0;
  const ownerRevenue = reservation.totals?.owner ?? 0;

  // COLORS
  const ownerRevenueColor = theme.colors.blue[5];
  const pmRevenueColor = theme.colors.violet[5];
  const taxesColor = theme.colors.orange[5];
  const borderColor = theme.colors.neutral[2];

  const formatted = {
    pmRevenue: formatCurrency(pmRevenue / 100, currency),
    ownerRevenue: formatCurrency(ownerRevenue / 100, currency),
    taxes: formatCurrency(taxes / 100, currency),
    reservation: formatCurrency(total / 100, currency),
  };

  return (
    <SimpleGrid
      cols={{ lg: 4 }}
      spacing={'1px'}
      sx={{
        backgroundColor: borderColor,
        border: '1px solid',
        borderColor,
        borderRadius: theme.radius.md,
        overflow: 'hidden',
        ' > div': {
          paddingInline: theme.spacing.xs,
          paddingTop: theme.spacing.sm,
          paddingBottom: theme.spacing.xs,
        },
      }}
    >
      <Stat label="Reservation">{formatted.reservation}</Stat>
      <Stat label="PM revenue" color={pmRevenueColor}>
        {formatted.pmRevenue}
      </Stat>
      <Stat label="Taxes" color={taxesColor}>
        {formatted.taxes}
      </Stat>

      <HoverCard withArrow shadow="md" position="bottom-end" arrowOffset={55}>
        <HoverCard.Target>
          <Stat label="Owner revenue" color={ownerRevenueColor}>
            {formatted.ownerRevenue}
          </Stat>
        </HoverCard.Target>
        <HoverCard.Dropdown>
          <Stack gap="xs">
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Reservation
              </Text>
              <Text ta="right" fw={500}>
                {formatted.reservation}
              </Text>
            </Group>
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                PM revenue
              </Text>
              <Text ta="right" fw={500}>
                -{formatted.pmRevenue}
              </Text>
            </Group>
            <Group wrap="nowrap" align="flex-start">
              <Text c="gray" flex={1}>
                Taxes
              </Text>
              <Text ta="right" fw={500}>
                -{formatted.taxes}
              </Text>
            </Group>
          </Stack>
        </HoverCard.Dropdown>
      </HoverCard>

      <Card
        radius={0}
        sx={{
          gridColumn: 'span 4',
        }}
      >
        <Progress.Root size="sm">
          <Tooltip label="PM revenue" withArrow>
            <Progress.Section
              value={(pmRevenue / total) * 100}
              color="violet"
            />
          </Tooltip>
          <Tooltip label="Taxes" withArrow>
            <Progress.Section value={(taxes / total) * 100} color="orange" />
          </Tooltip>
          <Tooltip label="Owner revenue" withArrow>
            <Progress.Section
              value={(ownerRevenue / total) * 100}
              color="blue"
            />
          </Tooltip>
        </Progress.Root>
      </Card>
    </SimpleGrid>
  );
};

const Stat = forwardRef<
  HTMLDivElement,
  {
    color?: string;
    label: string;
    children: string;
  } & ComponentPropsWithoutRef<'div'>
>(({ children, label, color, ...props }, ref) => {
  const theme = useMantineTheme();

  return (
    <Card radius={0} ref={ref} {...props}>
      <Group mb={rem(3)} gap={rem(4)} wrap="nowrap" align="flex-start">
        {color && (
          <Box
            mt={1.5}
            sx={{
              height: rem(8),
              borderRadius: '10px',
              width: '2px',
              flexShrink: 0,
              backgroundColor: color,
            }}
          />
        )}
        <Text c={theme.colors.gray[6]} size={rem(12)}>
          {label}
        </Text>
      </Group>
      <Text fw={500}>{children}</Text>
    </Card>
  );
});

const IsPriorToStartDate = () => {
  const theme = useMantineTheme();

  return (
    <Box
      sx={(theme) => ({
        boxShadow: theme.shadows.md,
        // zIndex: 100,
        border: '1px solid',
        borderColor: theme.colors.yellow[5],
        backgroundColor: theme.colors.yellow[0],
        padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
        borderRadius: theme.radius.md,
      })}
    >
      <Title order={5} fw={500} mb={rem(5)} c={theme.colors.neutral[7]}>
        Reservations Revenue Recognition - Prior to Team's Start Date
      </Title>

      <Text size="xs" c="neutral">
        This reservation's revenue recognition is prior to the team's start date
        and has therefore been excluded. No active journal entries were created
        and won't be used on owner statements.
      </Text>
    </Box>
  );
};

const Financials = ({
  lines,
  currency,
  title,
  reservationId,
  financials,
  status,
  totals,
  openDeleteAdjustment,
  openEditAdjustment,
  isCancelledAndPending,
  payment,
}: {
  currency: Reservation['currency'];
  financials: Reservation['financials'];
  totals: Reservation['totals'];
  status: Reservation['status'];
  payment: Reservation['payment'];
  lines: Reservation['lines'];
  // status: Reservation['status'];
  isCancelledAndPending: Reservation['isCancelledAndPending'];
  title: string;
  reservationId: string;
  openDeleteAdjustment: ReturnType<
    typeof useAdjustmentModals
  >['remove']['setOpened'];
  openEditAdjustment: ReturnType<
    typeof useAdjustmentModals
  >['edit']['setOpened'];
}) => {
  const theme = useMantineTheme();
  const [showAutoAdjustment, setShowAutoAdjustment] = useState(true);

  const [teamId] = useTeamId();
  const client = useGqtyClient();

  const [loading, setLoading] = useState(false);

  const excluded = lines.filter((line) => line.isExcluded);
  const included = lines.filter((line) => !line.isExcluded);

  const total = totals?.total ?? 0;

  type RowData = Reservation['financials'][0];

  const isCancelled = status === 'canceled';

  const rowData = useMemo(() => {
    if (isCancelled) {
      return [
        ...included.map<RowData>((line) => {
          return {
            account: line.account,
            lineType: line.type,
            recurringFee: null,
            transaction: null,
            id: line.id,
            reservationLine: null,
            centTotal: line.amount,
            status: 'inactive',
            title: line.description ?? '-',
            isAdjustment: false,
            party: 'owners',
          };
        }),
        ...financials,
      ];
    }

    return financials;
  }, [isCancelled, financials, included]);

  const columns = useMemo<MRT_ColumnDef<RowData>[]>(
    () => [
      {
        header: 'Description',
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        mantineTableBodyCellProps: {
          px: 'xs',
        },
        Footer: () => (
          <Text span fw={500}>
            Total guest charged
          </Text>
        ),
        Cell: ({ row }) => {
          const description = row.original.title;
          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          if (row.original.isAdjustment)
            return (
              <Box>
                <Text ta="left">{description}</Text>
                <Text size="xs" ta="left" c="gray">
                  Financials Adjustment
                </Text>
              </Box>
            );

          return (
            <Text td={td} ta="left">
              {description}
            </Text>
          );
        },
      },
      {
        header: 'amount',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(
            (row.original.centTotal || 0) / 100,
            currency
          );

          const td =
            row.original.status === 'inactive' ? 'line-through' : undefined;

          return (
            <Group gap={5} wrap="nowrap">
              {row.original.isAdjustment ? (
                <IconButton
                  icon="TrashIcon"
                  size={16}
                  disabled={!row.original.reservationLine?.id}
                  tooltip="Delete adjustment"
                  onClick={() => {
                    if (!row.original.reservationLine?.id) return;

                    openDeleteAdjustment({
                      reservationId,
                      id: row.original.reservationLine.id,
                    });
                  }}
                />
              ) : (
                row.original.reservationLine?.type && (
                  <>
                    <IconButton
                      icon="PercentageCircleIcon"
                      size={16}
                      tooltip="Add PM fee"
                      onClick={() =>
                        openEditAdjustment({
                          centTotal: undefined,
                          description: `${row.original.title} fee`,
                          lineType: row.original.reservationLine!.type!,
                          party: 'manager',
                          reservationId,
                        })
                      }
                      className="add-adjustment-icon"
                    />
                    <IconButton
                      icon="Edit3Icon"
                      size={16}
                      tooltip="Add financial adjustment"
                      className="add-adjustment-icon"
                      onClick={() =>
                        openEditAdjustment({
                          centTotal: undefined,
                          description: `${row.original.title} adjustment`,
                          lineType: row.original.reservationLine!.type!,
                          party: 'owners',
                          reservationId,
                        })
                      }
                    />
                  </>
                )
              )}
              <Text td={td}>{amount}</Text>
            </Group>
          );
        },
        Footer: () => (
          <Text span fw={500} ta="right" display={'block'}>
            {formatCurrency(total / 100, currency)}
          </Text>
        ),
      },
    ],
    [currency, total, reservationId, openDeleteAdjustment, openEditAdjustment]
  );

  const autobalance = useCallback(async () => {
    setLoading(true);

    const cancellationLineType = await client
      .query((q) => {
        return (
          q
            .paymentLineClassifications({
              where: {
                lines: {
                  tenantId: { _eq: teamId },
                },
                type: { _eq: 'reservationLine' },
                name: { _ilike: '%cancellation%' },
                lineTypeAccounts: {},
              },
            })
            .map((line) => line.name)[0] ?? null
        );
      })
      .catch(() => null);

    openEditAdjustment({
      centTotal: payment.expected - payment.received,
      party: 'owners',
      description: 'Cancellation revenue',
      reservationId,
      lineType: cancellationLineType,
    });

    setLoading(false);
  }, [
    client.query,
    teamId,
    reservationId,
    openEditAdjustment,
    payment.expected,
    payment.received,
  ]);

  return (
    <>
      {isCancelledAndPending && showAutoAdjustment && (
        <Box
          sx={(theme) => ({
            boxShadow: theme.shadows.md,
            // zIndex: 100,
            border: '1px solid',
            borderColor: theme.colors.yellow[5],
            backgroundColor: theme.colors.yellow[0],
            padding: `${theme.spacing.sm} ${theme.spacing.sm}`,
            borderRadius: theme.radius.md,
          })}
        >
          <Title order={5} fw={500} mb={rem(5)} c={theme.colors.neutral[7]}>
            Reservation Cancelled - Action Required
          </Title>

          <Text mb="md" size="xs" c="neutral">
            This reservation was canceled, leaving a balance of{' '}
            {formatCurrency(payment.received / 100, currency)} from previous
            payments.
          </Text>

          <Group mb={rem(8)} gap="xs" justify="flex-start">
            <Icon icon="CalendarCrossIcon" size={16} />
            <Text size="sm" c={theme.colors.neutral[7]}>
              Cancelled Reservation:
            </Text>
            <Badge
              color="teal"
              size="md"
              sx={{
                marginLeft: 'auto',
              }}
            >
              {formatCurrency(payment.expected / 100, currency)}
            </Badge>
          </Group>

          <Group mb="md" gap="xs" justify="flex-start">
            <Icon icon="CreditCardIncomeIcon" size={16} />
            <Text size="sm" c={theme.colors.neutral[7]}>
              Current Payments Total:
            </Text>
            <Badge
              color="blue"
              size="md"
              sx={{
                marginLeft: 'auto',
              }}
            >
              {formatCurrency(payment.received / 100, currency)}
            </Badge>
          </Group>

          <Group justify="end">
            <Button
              size="xs"
              onClick={() => setShowAutoAdjustment(false)}
              disabled={loading}
            >
              Close
            </Button>
            <Button
              size="xs"
              variant="primary"
              onClick={autobalance}
              loading={loading}
            >
              Add{' '}
              {formatCurrency(
                (payment.expected - payment.received) / 100,
                currency
              )}{' '}
              adjustment
            </Button>
          </Group>
        </Box>
      )}
      <DrawerCollapsableTable
        title={title}
        customRowCount={
          !excluded.length ? undefined : (
            <>
              <HoverCard withArrow shadow="md">
                <HoverCard.Target>
                  <Text span size="xs">
                    {included.length} ({excluded.length})
                  </Text>
                </HoverCard.Target>
                <HoverCard.Dropdown>
                  <Stack gap="xs">
                    {!!included.length && (
                      <>
                        <Text fw={500} flex={1}>
                          Included financials
                        </Text>
                        {included.map((line) => (
                          <Group key={line.id} wrap="nowrap" align="flex-start">
                            <Text c="gray" flex={1}>
                              {line.description}
                            </Text>
                            <Text ta="right" fw={500}>
                              {formatCurrency(
                                (line.amount ?? 0) / 100,
                                currency
                              )}
                            </Text>
                          </Group>
                        ))}
                      </>
                    )}
                    {!!excluded.length && (
                      <>
                        <Text fw={500} flex={1}>
                          Excluded financials
                        </Text>
                        {excluded.map((line) => (
                          <Group key={line.id} wrap="nowrap" align="flex-start">
                            <Text c="gray" flex={1}>
                              {line.description}
                            </Text>
                            <Text ta="right" fw={500}>
                              {formatCurrency(
                                (line.amount ?? 0) / 100,
                                currency
                              )}
                            </Text>
                          </Group>
                        ))}
                      </>
                    )}
                  </Stack>
                </HoverCard.Dropdown>
              </HoverCard>
            </>
          )
        }
        rightSection={
          <Button
            variant="light"
            size="xs"
            leftIcon="PlusIcon"
            color="neutral"
            iconColor={theme.colors.neutral[5]}
            onClick={() =>
              openEditAdjustment({
                centTotal: undefined,
                description: '',
                lineType: null,
                reservationId,
                party: 'owners',
              })
            }
          >
            Add adjustment
          </Button>
        }
        rowData={rowData}
        columns={columns}
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" c="gray">
              No financials available
            </Text>
          </Center>
        )}
        styles={{
          row: () => {
            return {
              '.add-adjustment-icon': {
                opacity: 0,
                transition: 'opacity 0.2s ease-in-out',
              },
              '&:hover .add-adjustment-icon': {
                opacity: 1,
              },
            };
          },
        }}
      />
    </>
  );
};

const Deposits = ({
  deposits,
  currency,
  title,
}: {
  deposits: Reservation['deposits'];
  title: string;
  currency: Reservation['currency'];
}) => {
  const { open: openDeposit } = useDepositDetailDrawer();

  const total = sum(deposits, 'centTotal') / 100;

  const columns = useMemo<MRT_ColumnDef<Reservation['deposits'][0]>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Cell: ({ row }) => {
          const deposit = row.original;

          return (
            <Box>
              <Text lineClamp={2}>{deposit.description}</Text>
              <Text c="gray" size="xs">
                {deposit.date ? utc(deposit.date).format('LL') : ''}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Badge',
        maxSize: 0,
        minSize: 120,
        size: 120,
        Cell: ({ row }) => {
          const connection = row.original.connection;

          return (
            <Group
              wrap="nowrap"
              gap={5}
              sx={(theme) => ({
                backgroundColor: theme.colors.gray[1],
                border: `1px solid ${theme.colors.gray[2]}`,
                paddingInline: rem(6),
                paddingBlock: rem(3),
                borderRadius: theme.radius.xl,
              })}
            >
              {connection?.app?.iconRound ? (
                <Avatar
                  size={16}
                  radius="xl"
                  mr={rem(3)}
                  src={connection.app.iconRound}
                />
              ) : (
                <Icon icon="PlusCircleIcon" size={14} />
              )}
              <Text span size="xs">
                {connection?.app?.name || 'Manual'}
              </Text>
            </Group>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        Cell: ({ row }) => {
          return formatCurrency(row.original.centTotal / 100, currency);
        },
      },
    ],
    [currency]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Text size="sm" fw={500}>
          {formatCurrency(total, currency)}
        </Text>
      }
      rowData={deposits}
      columns={columns}
      onRowClick={{
        handler: (row) => {
          if (row.original.id) openDeposit(row.original.id, 'push');
        },
        disabled: (row) => !row.original.id,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No payments / refunds found
          </Text>
        </Center>
      )}
    />
  );
};

const Expenses = ({
  expenses,
  currency,
  title,
  reservationId,
}: {
  expenses: Reservation['expenses'];
  currency: Reservation['currency'];
  title: string;
  reservationId: string;
}) => {
  const { open: openExpense } = useExpenseDetailDrawer();

  const gotoAddView = useAddExpenseNavigate();
  const total = sum(expenses, 'centTotal') / 100;

  const columns = useMemo<MRT_ColumnDef<Reservation['expenses'][0]>[]>(
    () => [
      {
        header: 'Description',
        accessorKey: 'description',
        Footer: () => (
          <Text span fw={500}>
            Total Expenses
          </Text>
        ),
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        Cell: ({ row }) => {
          const line = row.original;

          return (
            <Box>
              <Group gap={rem(8)}>
                <Text lineClamp={2}>{line.description}</Text>
                {line.party === 'owners' && (
                  <Badge size="xs">Billed to owner</Badge>
                )}
              </Group>
              <Text c="gray" size="xs">
                {line.expense.date ? utc(line.expense.date).format('LL') : ''}
              </Text>
            </Box>
          );
        },
      },
      {
        header: 'Badge',
        maxSize: 0,
        minSize: 120,
        size: 120,
        Cell: ({ row }) => {
          const expense = row.original.expense;

          return (
            <Tooltip label={expense.description}>
              <Group
                wrap="nowrap"
                gap={5}
                sx={(theme) => ({
                  backgroundColor: theme.colors.gray[1],
                  border: `1px solid ${theme.colors.gray[2]}`,
                  paddingInline: rem(6),
                  paddingBlock: rem(3),
                  borderRadius: theme.radius.xl,
                })}
              >
                <Icon icon="MinusCircleIcon" size={14} />
                <Text span size="xs" maw={100} truncate>
                  Expense
                </Text>
              </Group>
            </Tooltip>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 0,
        minSize: 90,
        size: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          return formatCurrency(
            row.original.centTotal / 100,
            row.original.expense.currency
          );
        },
        Footer: () => {
          return (
            <Text span fw={500} ta="right" display={'block'}>
              {formatCurrency(total, currency)}
            </Text>
          );
        },
      },
    ],
    [total, currency]
  );

  return (
    <DrawerCollapsableTable
      title={title}
      rightSection={
        <Button
          leftIcon="PlusIcon"
          color="neutral"
          variant="light"
          size="xs"
          onClick={() =>
            gotoAddView({
              currency: currency as currency_enum,
              reservationId,
            })
          }
        >
          Add expense
        </Button>
      }
      rowData={expenses}
      columns={columns}
      onRowClick={{
        handler: (row) => {
          if (row.original.expense.id)
            openExpense(row.original.expense.id, 'push');
        },
        disabled: (row) => !row.original.expense.id,
      }}
      emptyRowsFallback={() => (
        <Center>
          <Text size="sm" c="gray">
            No expenses found
          </Text>
        </Center>
      )}
    />
  );
};

function useFeeModals() {
  const [opened, setOpened] = useState<{
    reservationId: string;
    recurringFeeId: string | null;
    description: string;
    centTotal: number | undefined;
  } | null>(null);

  const [removeModal, setRemoveModal] = useState<{
    id: string;
    reservationId: string;
  } | null>(null);

  return {
    edit: {
      opened,
      setOpened,
    },
    remove: {
      opened: removeModal,
      setOpened: setRemoveModal,
    },
  };
}

const FeesAndCommissions = ({
  rowData,
  currency,
  title,
  reservationId,
}: {
  rowData: Reservation['feesAndCommissions'];
  reservationId: string;
  title: string;
  currency: Reservation['currency'];
}) => {
  const theme = useMantineTheme();

  const { edit, remove } = useFeeModals();

  const total = sum(rowData, (x) => x.centTotal) / 100;

  const columns = useMemo<
    MRT_ColumnDef<Reservation['feesAndCommissions'][0]>[]
  >(
    () => [
      {
        header: 'Title',
        accessorKey: 'title',
        Footer: () => (
          <Text span fw={500}>
            Total to PM
          </Text>
        ),
        mantineTableFooterCellProps: {
          pl: 'xs',
        },
        Cell: ({ row }) => {
          const goto = useNavigate();

          const recurringFee = row.original.recurringFee;

          const icon = ((): IconDefinition => {
            if (recurringFee?.type)
              return FEE_TYPES[
                getFeeTypeRouteFromRecurringFeeEnum(recurringFee.type)
              ].icon;

            return 'CircleDollarIcon';
          })();

          return (
            <Group wrap="nowrap" gap="xs">
              <Center>
                <Tooltip
                  disabled={!recurringFee?.id}
                  withArrow
                  label={
                    recurringFee?.type &&
                    FEE_TYPES[
                      getFeeTypeRouteFromRecurringFeeEnum(recurringFee.type)
                    ].title
                  }
                >
                  <Icon icon={icon} size={16} />
                </Tooltip>
              </Center>
              <Box>
                {recurringFee?.id ? (
                  <Tooltip
                    disabled={!row.original.isAdjustment}
                    label={recurringFee.name}
                    withArrow
                  >
                    <Anchor
                      component="button"
                      c="dark"
                      ta="left"
                      onClick={() => {
                        const url = new URL(
                          `/fees-commission/${recurringFee!.id}/edit`,
                          window.location.origin
                        );

                        url.searchParams.set(
                          FEE_RESERVATION_PREVIEW_SEARCH_KEY,
                          reservationId
                        );

                        goto(url.pathname + url.search);
                      }}
                    >
                      {row.original.title}
                    </Anchor>
                  </Tooltip>
                ) : (
                  <Text c="dark" ta="left">
                    {row.original.title}
                  </Text>
                )}
                {row.original.isAdjustment && (
                  <Text size="xs" c="gray" ta="left">
                    {recurringFee?.id ? 'Fee Adjustment' : 'Financials Fee'}
                  </Text>
                )}
              </Box>
            </Group>
          );
        },
      },
      {
        header: 'amount',
        accessorKey: 'centTotal',
        maxSize: 90,
        mantineTableBodyCellProps: {
          align: 'right',
        },
        mantineTableFooterCellProps: {
          align: 'right',
          pr: 'xs',
        },
        Cell: ({ row }) => {
          const amount = formatCurrency(row.original.centTotal / 100, currency);

          const isAdjustment = row.original.isAdjustment;

          return (
            <Group gap={5} wrap="nowrap">
              <IconButton
                className={isAdjustment ? '' : 'add-adjustment-icon'}
                icon={isAdjustment ? 'TrashIcon' : 'PlusIcon'}
                size={16}
                tooltip={isAdjustment ? 'Delete adjustment' : 'Add adjustment'}
                onClick={() =>
                  isAdjustment
                    ? row.original.reservationLine?.id
                      ? remove.setOpened({
                          reservationId,
                          id: row.original.reservationLine.id,
                        })
                      : null
                    : edit.setOpened({
                        reservationId,
                        centTotal: undefined,
                        description: `${row.original.title} adjustment`,
                        recurringFeeId: row.original.recurringFee!.id,
                      })
                }
              />
              <Text
                ta="right"
                sx={{
                  flexShrink: 0,
                }}
              >
                {amount}
              </Text>
            </Group>
          );
        },
        Footer: () => {
          return (
            <Text span fw={500} ta="right" display={'block'}>
              {formatCurrency(total, currency)}
            </Text>
          );
        },
      },
    ],
    [currency, total, remove.setOpened, reservationId, edit.setOpened]
  );

  return (
    <>
      <DrawerCollapsableTable
        title={title}
        rowData={rowData}
        columns={columns}
        rightSection={
          <Button
            variant="light"
            size="xs"
            leftIcon="PlusIcon"
            color="neutral"
            iconColor={theme.colors.neutral[5]}
            onClick={() =>
              edit.setOpened({
                centTotal: undefined,
                description: '',
                reservationId,
                recurringFeeId: null,
              })
            }
          >
            Add adjustment
          </Button>
        }
        emptyRowsFallback={() => (
          <Center>
            <Text size="sm" c="gray">
              No fees and commissions found
            </Text>
          </Center>
        )}
        styles={{
          row: () => {
            return {
              '.add-adjustment-icon': {
                opacity: 0,
                transition: 'opacity 0.2s ease-in-out',
              },
              '&:hover .add-adjustment-icon': {
                opacity: 1,
              },
            };
          },
        }}
      />
      <ReservationFeeAddModal
        reservationId={reservationId}
        closeModal={() => edit.setOpened(null)}
        recurringFee={edit.opened}
      />
      <ReservationFeeDeleteModal
        reservationId={reservationId}
        closeModal={() => remove.setOpened(null)}
        recurringFee={remove.opened}
      />
    </>
  );
};
