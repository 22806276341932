import {
  type QueryKey,
  useApiClient,
  useGqtyClient,
  useTeamId,
} from '@finalytic/data';
import type { party_enum } from '@finalytic/graphql';
import { type Maybe, hasValue } from '@finalytic/utils';
import { randomId, useDebouncedValue } from '@mantine/hooks';
import { useQuery as useTanstackQuery } from '@tanstack/react-query';

export function useLedgerReservationDetailDrawerQuery(id: Maybe<string>) {
  const [teamId] = useTeamId();

  const $api = useApiClient();
  const $client = useGqtyClient();

  const query = useTanstackQuery({
    queryKey: ['reservations' satisfies QueryKey, id, teamId],
    enabled: !!id && !!teamId,
    queryFn: async () => {
      if (!id) throw new Error('Missing reservation id');

      const [apiResult, dbResult] = await Promise.all([
        $api.GET('/reservations/{id}', {
          params: {
            path: {
              id: id!,
            },
            query: {
              includeFinancials: true,
            },
          },
        }),
        $client.query((q) => {
          const depositApps = q
            .transactions({
              where: {
                type: { _eq: 'deposit' },
                tenantId: { _eq: teamId },
                lines: {
                  reservationId: { _eq: id },
                },
              },
              distinct_on: ['connectionId'],
            })
            .map((transaction) => ({
              id: transaction.connection?.appId,
              name: transaction.connection?.app?.name,
              iconRound: transaction.connection?.app?.iconRound,
            }));

          const reservationApp = q
            .reservations({
              where: {
                tenantId: { _eq: teamId },
                id: { _eq: id },
              },
              limit: 1,
            })
            .map((res) => {
              const app = res?.connection?.app;

              return {
                id: app?.id,
                name: app?.name,
                iconRound: app?.iconRound,
              };
            })
            .find((x) => x.id);

          return {
            depositApps,
            reservationApp,
          };
        }),
      ]);

      if (apiResult.error) {
        throw apiResult.error;
      }

      const reservation = apiResult.data;

      type apiFinancialLine = NonNullable<
        typeof reservation.financials
      >['lines'][number];

      type FinancialLine = {
        id: string;
        recurringFee: apiFinancialLine['recurringFee'];
        reservationLine: apiFinancialLine['line'];
        isAdjustment: boolean;
        title: string;
        status: apiFinancialLine['status'];
        centTotal: number;
      };

      type Expense = {
        id: string;
        centTotal: number;
        description: string;
        party: party_enum | undefined | null;
        expense: {
          id: string;
          description: string;
          currency: string;
          date: any;
        };
      };

      const currency = reservation.currency ?? undefined;

      const folio =
        reservation.financials?.lines.filter(
          (x) => x.type === 'adjustment' || x.type === 'financial'
        ) ?? [];

      const feesAndCommissions: FinancialLine[] = (
        folio
          .map((line) => {
            if (line.party !== 'manager') return null;

            return {
              id: randomId(),
              centTotal: line.amount,
              isAdjustment: line.type === 'adjustment',
              recurringFee: line.recurringFee,
              title: line.name,
              reservationLine: line.line,
              status: line.status,
            };
          })
          .filter(hasValue) ?? []
      ).sort((a, b) => {
        // 1. isAdjustment
        // 2. amount descending
        const aValue = `${a.isAdjustment ? '0' : '1'}${a.centTotal}`;
        const bValue = `${b.isAdjustment ? '0' : '1'}${b.centTotal}`;

        return bValue.localeCompare(aValue);
      });

      const expenses: Expense[] =
        reservation.financials?.lines
          .map<Expense | null>((line) => {
            if (line.type !== 'expense' || !line.transaction) return null;

            return {
              id: line.transaction?.id,
              centTotal: line.amount,
              description: line.name,
              party: line.party,
              expense: {
                currency: line.transaction.currency,
                date: line.transaction.date,
                description: line.transaction.description,
                id: line.transaction.id,
              },
            };
          })
          .filter(hasValue) ?? [];

      type Deposit = {
        id: string;
        description: string;
        currency: string;
        date: string;
        centTotal: number;
        connection: {
          id: string;
          name: string;
          app:
            | {
                id: string;
                name: string;
                iconRound: string | undefined;
              }
            | undefined;
        } | null;
      };

      const deposits: Deposit[] =
        reservation.financials?.lines
          .filter((line) => line.type === 'deposit' && line.transaction)
          .reduce<Deposit[]>((acc, line) => {
            const transaction = line.transaction;

            if (!transaction) return acc;

            const index = acc.findIndex((d) => d.id === transaction.id);

            const app = dbResult.depositApps.find(
              (app) => app.id === transaction.connection?.appId
            );

            if (index === -1) {
              acc.push({
                id: transaction.id,
                centTotal: line.amount,
                description: transaction?.description,
                currency: transaction.currency,
                date: transaction.date,
                connection: transaction.connection
                  ? {
                      id: transaction.connection.id,
                      name: transaction.connection.name,
                      app: app?.id
                        ? {
                            id: app.id,
                            name: app.name ?? '-',
                            iconRound: app.iconRound,
                          }
                        : undefined,
                    }
                  : null,
              });
            } else {
              acc[index].centTotal += line.amount;
            }

            return acc;
          }, []) ?? [];

      const financials: FinancialLine[] =
        reservation.financials?.lines
          .map<FinancialLine | null>((line) => {
            if (line.party !== 'owners') return null;

            return {
              id: randomId(),
              title: line.name,
              centTotal: line.amount,
              recurringFee: line.recurringFee,
              reservationLine: line.line,
              status: line.status,
              isAdjustment: line.type === 'adjustment',
            };
          })
          .filter(hasValue)
          .sort((a, b) => {
            // sort first values with isAdjustment false and then by centTotal descending
            const aValue = `${a.isAdjustment ? '0' : '1'}${a.centTotal}`;
            const bValue = `${b.isAdjustment ? '0' : '1'}${b.centTotal}`;

            return bValue.localeCompare(aValue);
          }) ?? [];

      return {
        ...reservation,
        totals: reservation.financials?.totals,
        app: dbResult.reservationApp,
        financials,
        feesAndCommissions,
        expenses,
        deposits,
        isPriorToStartDate:
          !reservation.financials?.lines.length ||
          reservation.financials.lines.every((x) => x.status === 'inactive'),
        status: reservation.status,
        currency,
        isCancelledAndPending:
          reservation.status === 'canceled' &&
          reservation.payment.status !== 'paid',
      };
    },
  });

  const [debounced] = useDebouncedValue(query.data, 500);

  return { ...query, data: query.data || debounced };
}

export type Reservation = NonNullable<
  ReturnType<typeof useLedgerReservationDetailDrawerQuery>['data']
>;
