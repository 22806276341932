import { CountBagde } from '@finalytic/components';
import {
  useDashboard,
  useEnabledFeatures,
  useQuery,
  useSubscription,
  useTeam,
  useTeamId,
} from '@finalytic/data';
import { HiddenFeatureIndicator } from '@finalytic/data-ui';
import { Icon } from '@finalytic/icons';
import { IconButton } from '@finalytic/ui';
import { Box, Stack, Text } from '@mantine/core';
import {
  type TenantSetupGuide,
  XIMPLIFI_TENANT_ID,
} from '@vrplatform/ui-common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNavbarExpanded } from '../hooks';
import { NavbarRoute } from '../layout/navbar';
import {
  getPropertyManagementConnections,
  usePmIssuesAggregateQuery,
} from '../queries';
import { useSetupGuideStore } from '../stores/useSetupGuideStore';
import { NavigationSection as Section } from './NavigationSection';
import { NavbarSyncsIconButton } from './_components';

const ICON_SIZE = 18;

export const PmNavigation = () => {
  const { GL, GL_WITH_LEGACY_AUTOMATIONS } = useEnabledFeatures();
  const [dashboard] = useDashboard();

  const [searchParams] = useSearchParams();

  const getCurrentStatementsLink = () => {
    const base = '/statements/owner-statements';

    if (location.pathname.startsWith('/statement')) {
      const date = searchParams.get('date');

      return `${base}${date ? `?date=${date}` : ''}`;
    }

    return base;
  };

  return (
    <>
      {!GL && <UserGuide />}

      <Section>
        <NavbarRoute
          title="Dashboard"
          link="/"
          icon={<Icon icon="DashboardIcon" size={ICON_SIZE} />}
          notification={<PmIssueCountBagde />}
        />
      </Section>

      <Section>
        {GL && (
          <NavbarRoute
            title={'Bank reconciliation'}
            link={'/reconciliation'}
            icon={<Icon icon={'FileInvoiceIcon'} size={ICON_SIZE} />}
          />
        )}

        {(!GL || GL_WITH_LEGACY_AUTOMATIONS) && (
          <NavbarRoute
            title={'Payments'}
            link={'/payments'}
            icon={<Icon icon={'CircleDollarIcon'} size={ICON_SIZE} />}
          />
        )}

        {GL && (
          <>
            <NavbarRoute
              title="Deposits"
              link="/deposits"
              icon={<Icon icon="PlusCircleIcon" size={ICON_SIZE} />}
            />
            <NavbarRoute
              title="Expenses"
              link="/expenses"
              icon={<Icon icon="MinusCircleIcon" size={ICON_SIZE} />}
            />
          </>
        )}
        <NavbarRoute
          title="Reservations"
          link="/reservations"
          icon={<Icon icon="CalendarEventIcon" size={ICON_SIZE} />}
        />
        <NavbarRoute
          title="Listings"
          link="/listings"
          icon={<Icon icon="HomeIcon" size={ICON_SIZE} />}
        />

        <NavbarRoute
          title={GL ? 'Contacts' : 'Owners'}
          link="/owners"
          icon={<Icon icon="UserIcon" size={ICON_SIZE} />}
        />

        {(!GL || GL_WITH_LEGACY_AUTOMATIONS) && (
          <NavbarRoute
            title="Mappings"
            link="/mappings"
            icon={<Icon icon="RouteIcon" size={ICON_SIZE} />}
          />
        )}

        {GL ? (
          <NavbarRoute
            title="Fees and Commissions"
            link="/fees-commissions"
            icon={<Icon icon="PercentageIcon" size={ICON_SIZE} />}
          />
        ) : (
          <NavbarRoute
            title="Custom Fees"
            link="/custom-fees"
            icon={<Icon icon="PercentageIcon" size={ICON_SIZE} />}
          />
        )}

        {(!GL || GL_WITH_LEGACY_AUTOMATIONS) && (
          <NavbarRoute
            title="Automations"
            link="/automations"
            icon={<Icon icon="AutomationsIcon" size={ICON_SIZE} />}
          />
        )}

        {GL && (
          <NavbarRoute
            title="Reports"
            link="/general-ledger/detail"
            icon={<Icon icon="ListUnorderedIcon" size={ICON_SIZE} />}
          />
        )}
        <NavbarRoute
          title="Statements"
          link={getCurrentStatementsLink()}
          icon={<Icon icon="ClipboardTextIcon" size={ICON_SIZE} />}
        />
      </Section>

      <Section>
        <NavbarRoute
          title="Connections"
          link="/connections"
          icon={<Icon icon="ShareIcon" size={ICON_SIZE} />}
          notification={
            GL && dashboard === 'propertyManager' && <NavbarSyncsIconButton />
          }
        />

        <NavbarRoute
          title="Files"
          link="/files"
          icon={<Icon icon={'FolderOpenIcon'} size={ICON_SIZE} />}
        />
      </Section>

      <Legacy />
    </>
  );
};

function PmIssueCountBagde() {
  const { data, isInitialLoading: loading } = usePmIssuesAggregateQuery();
  const goto = useNavigate();

  const count = data?.aggregate || 0;

  if (loading)
    return <CountBagde count={count} loading={loading} loadingColor="white" />;

  if (!count) return null;

  return (
    <IconButton
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        goto('/dashboard/issues');
      }}
      sx={{
        width: 'unset',
      }}
    >
      <CountBagde count={count} loading={loading} loadingColor="white" />
    </IconButton>
  );
}

function UserGuide() {
  const setGuide = useSetupGuideStore((state) => state.setSetupGuide);

  const [teamId] = useTeamId();
  const _setup = useSubscription(
    (q, args) => {
      if (!args.teamId) return null;

      const tenant = q
        .tenant({
          where: {
            id: { _eq: args.teamId },
          },
          limit: 1,
        })
        .map(
          (tenant) => (tenant.setup() || null) as TenantSetupGuide | null
        )?.[0];

      return tenant || null;
    },
    {
      teamId,
    },
    {
      skip: !teamId,
      queryKey: ['setupGuide'],
      onSubscriptionData: (data) => {
        if (data) setGuide(data);
      },
    }
  );

  return (
    <HiddenFeatureIndicator permission="SETUP_GUIDE_UI">
      <Section>
        <NavbarRoute
          title="Getting started"
          link="/getting-started"
          icon={<Icon icon="Loader2Icon" size={ICON_SIZE} />}
        />
      </Section>
    </HiddenFeatureIndicator>
  );
}

const NavigationSection = ({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) => {
  const { isDesktopExpanded } = useNavbarExpanded();

  return (
    <Box>
      {title && isDesktopExpanded && (
        <Text
          component="span"
          mb="xs"
          c="white"
          pl={5}
          sx={{ opacity: 0.5, display: 'block' }}
        >
          {title}
        </Text>
      )}
      <Stack gap="xs">{children}</Stack>
    </Box>
  );
};

function Legacy() {
  // LEGACY
  const [dashboard] = useDashboard();
  const [{ partnerId, id: teamId }] = useTeam();

  const { data } = useQuery(
    (q, args) => {
      const retoolViews = q
        .views({
          where: {
            tenantId: { _eq: args.teamId },
            group: { _eq: 'Reports' },
          },
          order_by: [{ sortOrder: 'asc_nulls_last' }],
        })
        .map((view) => ({
          id: view.id!,
          title: view.title || '',
          url: view.retoolViewId!,
          group: view.group?.toLowerCase() || '',
        }));

      const hasPmsConnection = !!getPropertyManagementConnections(
        q,
        { teamId: args.teamId },
        { limit: 1 }
      ).length;

      return {
        retoolViews,
        hasPmsConnection,
      };
    },
    {
      queryKey: 'views',
      variables: {
        teamId,
      },
    }
  );

  const retoolViews = data?.retoolViews || [];

  const showMappingsV2 =
    partnerId === XIMPLIFI_TENANT_ID &&
    data !== undefined &&
    !data.hasPmsConnection;

  const reViews = retoolViews.filter(
    (x) => !showMappingsV2 || x.url !== '11ef2837-7612-48db-a7c7-68c621d41efc'
  );

  if (!showMappingsV2 && !reViews?.length && dashboard !== 'partner') {
    return null;
  }

  return (
    <NavigationSection title={'Legacy'}>
      {/* LEGACY */}
      {showMappingsV2 && (
        <NavbarRoute
          title="Mappings"
          link="/reports/11ef2837-7612-48db-a7c7-68c621d41efc"
          icon={<Icon icon="FileTextIcon" size={ICON_SIZE} />}
        />
      )}
      {reViews?.length > 0 && (
        <NavbarRoute
          title="Reports"
          link={`/${retoolViews[0].group}/${retoolViews[0].url}`}
          icon={<Icon icon="FileTextIcon" size={ICON_SIZE} />}
          subRoutes={retoolViews}
        />
      )}
    </NavigationSection>
  );
}

// const SETUP_GUIDE_JSON: TenantSetupGuide = {
//   'e0de569c-7c39-48ed-bb2e-f654aa1c0e32': {
//     init: {
//       label: 'Init',
//       state: 'done',
//       action: {
//         key: 'init',
//         type: 'automation',
//         automationTemplate: 'quickbooksAccounting_initialData',
//       },
//       description: 'Creates items & accounts',
//     },
//     accountingConnection: {
//       appId: 'quickbooks',
//       connectionId: 'e0de569c-7c39-48ed-bb2e-f654aa1c0e32',
//     },
//     customFees: {
//       label: 'Management Commission',
//       state: 'notStarted',
//       action: {
//         key: 'managementCommission',
//         type: 'automation',
//         automationTemplate: 'quickbooksAccounting_commission',
//       },
//       description: 'Management Commission',
//     },
//     automations: {
//       invoice: {
//         label: 'Invoice',
//         state: 'notStarted',
//         action: {
//           key: 'invoice',
//           type: 'automation',
//           automationTemplate: 'quickbooksAccounting_invoice',
//         },
//         description: 'Invoice',
//       },
//       payment: {
//         label: 'Payment',
//         state: 'notStarted',
//         action: {
//           key: 'payment',
//           type: 'automation',
//           automationTemplate: 'quickbooksAccounting_payment',
//         },
//         description: 'Payment',
//       },
//       journalEntry: {
//         label: 'Journal Entry',
//         state: 'notStarted',
//         action: {
//           key: 'journalEntry',
//           type: 'automation',
//           automationTemplate: 'quickbooksAccounting_journalEntry',
//         },
//         description: 'Journal Entry',
//       },
//     },
//     pmsConnection: {
//       appId: 'hostfully',
//       connectionId: '9c33f72e-8a09-49f3-b4c0-8cfed7497fda',
//     },
//     prerequisites: [],
//     ownerStatements: {
//       label: 'Owner Statement',
//       state: 'notStarted',
//       action: {
//         key: 'ownerStatement',
//         type: 'automation',
//         automationTemplate: 'quickbooksAccounting_ownerStatements',
//       },
//       description: 'Owner Statement',
//     },
//     listingsAndOwners: {
//       importOwners: {
//         label: 'Import Owners',
//         state: 'notStarted',
//         action: {
//           key: 'owner',
//           type: 'extract',
//         },
//         description: 'Import Owners',
//       },
//       setupClasses: {
//         label: 'Setup Classes',
//         state: 'done',
//         action: {
//           key: 'class',
//           type: 'automation',
//           automationTemplate: 'quickbooksAccounting_class',
//         },
//         description: 'Setup Classes',
//       },
//       setupVendors: {
//         label: 'Setup Vendors',
//         state: 'notStarted',
//         action: {
//           key: 'vendor',
//           type: 'automation',
//           automationTemplate: 'quickbooksAccounting_owner',
//         },
//         description: 'Setup Vendors',
//       },
//       importListings: {
//         label: 'Import Listings',
//         state: 'notStarted',
//         action: {
//           key: 'listing',
//           type: 'extract',
//         },
//         description: 'Import Listings',
//       },
//     },
//   },
// };
