import { type gqlV2, useTeamId } from '@finalytic/data';
import { whereConnections } from '@vrplatform/ui-common';
import { useMemo } from 'react';
import { useConnectionFilter } from './ConnectionFilter';

export function useWhereConnections({
  onlyArchived,
}: { onlyArchived: boolean }) {
  const { filter } = useConnectionFilter();
  const [teamId] = useTeamId();

  const appId = filter.appId;
  const search = filter.search?.trim();
  const status = onlyArchived ? 'archived' : (filter.status as any);

  const where = useMemo<gqlV2.connection_bool_exp>(
    () =>
      whereConnections({
        teamId,
        search,
        status,
        appId,
      }),
    [teamId, appId, search, status]
  );

  return where;
}
