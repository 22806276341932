import {
  useEnabledFeatures,
  useMe,
  useQuery,
  useTeamId,
} from '@finalytic/data';
import type {
  Query,
  connection_bool_exp,
  listing_bool_exp,
} from '@finalytic/graphql';
import { type Maybe, day, ensure, sortBy } from '@finalytic/utils';
import { Box } from '@mantine/core';
import {
  getActionMessage,
  getListingAddress,
  getListingName,
  whereConnections,
  whereListings,
  whereSettingAcrossAutomations,
} from '@vrplatform/ui-common';
import type { ReactNode } from 'react';
import { useListingClassMappingInfo } from '../hooks';
import { ConnectionNameCell } from '../views/connections/connections-table/ConnectionNameCell';
import { ListingNameCell } from '../views/listings/_components';
import { getMissingAccountAssignments } from './useMissingAccountAssignmentsQuery';

export type IssueFilter = {
  search: Maybe<string>;
  level?: Maybe<'error' | 'warning'>;
};

export type IssueRow = {
  id: string;
  date: string | null;
  type:
    | 'connection-error'
    | 'connection-version-outdated'
    | 'owner-statement-reservation'
    | 'listing-class-missing'
    | 'listing-owner-missing'
    | 'account-assignment-missing'
    | 'payment-duplicate';
  table: 'connection' | 'owner-statement' | 'listing' | 'account' | 'payment';
  level: 'error' | 'warning';
  message: string;
  item: ReactNode;
  itemName: string;
};

export function usePmIssuesAggregateQuery() {
  const [teamId] = useTeamId();
  const { GL } = useEnabledFeatures();
  const { id: meId } = useMe();

  const listingClassMapping = useListingClassMappingInfo();

  return useQuery((q, args) => getIssues(q, args, { limit: 0, offset: 0 }), {
    keepPreviousData: true,
    queryKey: ['issues', 'issueMessageOverwrites'],
    refetchOnWindowFocus: true,
    skip: !teamId,
    variables: ensure<Parameters<typeof getIssues>[1]>({
      teamId,
      GL,
      onlyAggregate: true,
      search: undefined,
      listingClassMapping,
      meId,
    }),
  });
}

export function getIssues(
  q: Query,
  args: {
    teamId: string;
    GL: boolean;
    meId: string;
    onlyAggregate: boolean;
    listingClassMapping: ReturnType<typeof useListingClassMappingInfo>;
  } & IssueFilter,
  opts: {
    limit: number;
    offset: number;
  }
): {
  aggregate: number;
  list: IssueRow[];
} {
  const limit = opts.limit;
  const offset = opts.offset;

  const overwrites = q
    .issueMessageOverwrites({
      order_by: [{ pattern: 'asc_nulls_last' }],
    })
    .map((o) => ({
      pattern: o.pattern || '',
      message: o.message || '',
    }));

  // const ownerStatementsAggregate = q
  //   .issueOwnerStatementLinesWithNotMatchingListingIds({
  //     where: {
  //       tenantId: { _eq: args.teamId },
  //     },
  //     args: {
  //       tenantId: args.teamId,

  //       // startAt: '2024-05-01'
  //     },
  //   })
  //   .map((x) => x.ownerStatementId).length;

  // const ownerStatements = q
  //   .issueOwnerStatementLinesWithNotMatchingListingIds({
  //     limit,
  //     offset,
  //     where: {
  //       tenantId: { _eq: args.teamId },
  //     },
  //     args: {
  //       tenantId: args.teamId,
  //       // startAt: '2024-05-01', // Can we remove startAt param?
  //     },
  //   })
  //   .map<IssueRow>((x) => ({
  //     id: x.ownerStatementId,
  //     type: 'owner-statement-reservation',
  //     date: day().yyyymmdd(),
  //     item: null,
  //     itemName: x.ownerStatementId,
  //     level: 'warning',
  //     message: 'Incorrect reservations',
  //     table: 'owner-statement',
  //   }));

  // things to check
  // 1. connections
  // 2. owner stateemtn => wrong reservation
  // 3. listings
  // 1. no owner assigned
  // 2. no class assigned (setting)

  const missingAccountAssignments =
    !args.GL || args.level === 'warning' || offset !== 0
      ? []
      : getMissingAccountAssignments(q, {
          teamId: args.teamId,
        }).map<IssueRow>((assignment) => ({
          id: assignment.id,
          type: 'account-assignment-missing',
          date: null,
          message: 'Missing account assignment',
          itemName: assignment.title,
          item: (
            <Box
              component="span"
              mr={5}
              sx={
                {
                  border: '1px solid #EEEFF1',
                  borderRadius: 5,
                  padding: '2px 7px',
                  backgroundColor: '#fff',
                  fontSize: 13,
                  lineHeight: '1rem',
                } as any
              }
            >
              {assignment.title}
            </Box>
          ),
          level: 'error',
          table: 'account',
        }));

  const outdatedConnections =
    args.level === 'warning' || offset !== 0
      ? []
      : q
          .issueConnectionsNeedVersionUpgrade({
            // order_by: [{ connectionUpdatedAt: 'desc_nulls_last' }],
            args: {
              id: args.teamId,
            },
          })
          .map<IssueRow>((x) => {
            return {
              id: x.connectionId,
              type: 'connection-version-outdated',
              date: day(x.connectionUpdatedAt).yyyymmdd(),
              message:
                'Please reconnect this connection to update the version.',
              itemName: x.connectionName || 'Missing connection name',
              item: (
                <ConnectionNameCell
                  icon={x.appIcon}
                  name={x.connectionName || 'Missing connection name'}
                />
              ),
              level: 'error',
              table: 'connection',
            };
          });

  const duplicatedPayments =
    args.level === 'warning' || offset !== 0
      ? []
      : q
          .issueDuplicatedPayments({
            args: {
              tenantId: args.teamId,
            },
            order_by: [{ paymentDate: 'desc_nulls_last' }],
          })
          .map<IssueRow>((x) => {
            return {
              id: x.paymentUniqueRef,
              type: 'payment-duplicate',
              date:
                x.paymentDate && day(x.paymentDate).isValid()
                  ? day(x.paymentDate).yyyymmdd()
                  : null,
              message: `We found the same payment synced from ${x.connectionNames()?.length ?? 0} different connections.`,
              itemName: x.paymentUniqueRef || 'Missing payment reference',
              item: (
                <Box
                  component="span"
                  mr={5}
                  sx={
                    {
                      border: '1px solid #EEEFF1',
                      borderRadius: 5,
                      padding: '2px 7px',
                      backgroundColor: '#fff',
                      fontSize: 13,
                      lineHeight: '1rem',
                    } as any
                  }
                >
                  {x.paymentUniqueRef}
                </Box>
              ),
              level: 'error',
              table: 'payment',
            };
          });

  const whereFailedConnections: connection_bool_exp = {
    ...whereConnections({
      teamId: args.teamId,
      status: 'error',
    }),
    appId: { _nin: ['finalytic', 'booking-com', 'api'] },
  };

  const failedConnections =
    args.onlyAggregate || args.level === 'warning'
      ? []
      : q
          .connection({
            where: whereFailedConnections,
            limit,
            offset,
            order_by: [{ updatedAt: 'desc' }],
          })
          .map<IssueRow>((connection) => {
            const lastFetch =
              connection
                .jobPlans({
                  where: {
                    jobs: {
                      kind: { _in: ['extract', 'extractLegacy'] },
                    },
                    isCurrentOnConnection: { _eq: true },
                  },
                  order_by: [{ createdAt: 'desc_nulls_last' }],
                })
                .map((plan) => ({
                  status: plan.status,
                  title: getActionMessage(plan.title || '', overwrites),
                  createdAt: plan.createdAt,
                }))[0] || null;

            return {
              id: connection.id,
              type: 'connection-error',
              table: 'connection',
              level: 'error',
              message: lastFetch?.title || 'Missing error',
              date: lastFetch?.createdAt,
              itemName: connection.name!,
              item: (
                <ConnectionNameCell
                  icon={connection?.app.iconRound}
                  name={connection.name}
                />
              ),
            };
          });

  const failedConnectionAggrgate =
    args.level === 'warning'
      ? 0
      : q
          .connectionAggregate({
            where: whereFailedConnections,
          })
          .aggregate?.count() || 0;

  const whereMissingOwner: listing_bool_exp = {
    ...whereListings({
      tenantId: args.teamId,
      dashboard: 'propertyManager',
      search: args.search,
      status: 'active',
      meId: args.meId,
      GL: args.GL,
    }),
    _not: args.GL
      ? {
          ownershipPeriods: {},
        }
      : {
          ownerships: {},
        },
  };

  const missingOwnerAggregate =
    args.level === 'error'
      ? 0
      : q
          .listingAggregate({
            where: whereMissingOwner,
          })
          .aggregate?.count() || 0;

  const missingOwners =
    args.onlyAggregate || args.level === 'error'
      ? []
      : q
          .listings({
            where: whereMissingOwner,
            limit,
            offset,
            order_by: [
              {
                createdAt: 'desc',
              },
            ],
          })
          .map<IssueRow>((listing) => {
            const lc = listing
              .connections({
                order_by: [{ connection: { app: { name: 'asc' } } }],
              })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                iconRound: conn.connection.app.iconRound,
              }));

            const listingName = getListingName(listing);

            return {
              id: listing.id,
              type: 'listing-owner-missing',
              table: 'listing',
              level: 'warning',
              message: 'No ownership assigned',
              itemName: listingName,
              date: listing.createdAt,
              item: (
                <ListingNameCell
                  address={getListingAddress(listing).full}
                  connections={lc}
                  name={listingName}
                  variant="compact"
                />
              ),
            };
          });

  const whereMissingClass: listing_bool_exp = {
    ...whereListings({
      tenantId: args.teamId,
      dashboard: 'propertyManager',
      search: args.search,
      status: 'active',
      meId: args.meId,
      GL: args.GL,
    }),
    _not: {
      settingsRight: {
        key: { _eq: args.listingClassMapping.mapping.key },
        _or: whereSettingAcrossAutomations({
          leftConnectionId:
            args.listingClassMapping.automation.finalyticConnectionId,
          leftSchema: args.listingClassMapping.mapping.leftType,
          rightSchema: args.listingClassMapping.mapping.rightType,
          rightConnectionId:
            args.listingClassMapping.automation.accountingConnectionId || '',
          automationId:
            args.listingClassMapping.automation.automation?.automationId,
        }),
      },
    },
  };

  const disableListingClassMapping =
    args.level === 'error' ||
    args.GL ||
    !args.listingClassMapping.automation.automation ||
    !args.listingClassMapping.accounting;

  const missingClassAggregate = disableListingClassMapping
    ? 0
    : q
        .listingAggregate({
          where: whereMissingClass,
        })
        .aggregate?.count() || 0;

  const missingClasses =
    args.onlyAggregate || disableListingClassMapping
      ? []
      : q
          .listings({
            where: whereMissingClass,
            limit,
            offset,
            order_by: [
              {
                createdAt: 'desc',
              },
            ],
          })
          .map<IssueRow>((listing) => {
            const lc = listing
              .connections({
                order_by: [{ connection: { app: { name: 'asc' } } }],
              })
              .map((conn) => ({
                id: conn.id,
                name: conn.name,
                iconRound: conn.connection.app.iconRound,
              }));

            const listingName = getListingName(listing);

            return {
              id: listing.id,
              type: 'listing-class-missing',
              table: 'listing',
              level: 'warning',
              message: 'No class assigned',
              itemName: listingName,
              date: listing.createdAt,
              item: (
                <ListingNameCell
                  address={getListingAddress(listing).full}
                  connections={lc}
                  name={listingName}
                  variant="compact"
                />
              ),
            };
          });

  const aggregate =
    failedConnectionAggrgate +
    missingOwnerAggregate +
    missingClassAggregate +
    outdatedConnections.length +
    missingAccountAssignments.length +
    duplicatedPayments.length;

  const list = sortBy(
    [
      ...missingAccountAssignments,
      ...outdatedConnections,
      ...failedConnections,
      ...duplicatedPayments,
      ...missingOwners,
      ...missingClasses,
    ],
    'date',
    'desc'
  );

  return {
    aggregate,
    list,
  };
}
