import type { action, change, job, jobPlan } from '@finalytic/graphql';
import { day, hasValue, toTitleCase } from '@finalytic/utils';
import { getListingName } from '@vrplatform/ui-common';
import type { WorkflowAction } from './_types';

export const getWorkflowActionRow = (action: action): WorkflowAction => {
  const links = action.sourceLinks();

  return {
    createdAt: action.createdAt,
    id: action.id,
    jobPlanId: action.jobPlanId!,
    jobId: action.jobId!,
    status: action.status,
    title: action.uniqueRef,
    message: action.title || toTitleCase(action?.status || ''),
    type: toTitleCase(action.schema?.uniqueRef || ''),
    uniqueRef: action.uniqueRef,
    automationId: action.automationId || action.jobPlan?.automationId || '',
    inputJson: action.inputJson() || {},
    outputJson: action.outputJson() || {},
    date: action.job?.params()?.title || action.jobPlan?.params()?.title || '',
    externalHref: action.source?.dataHref,
    // hasExternalLinks: !!action.schema?.hasExternalLinks,
    links: {
      paymentIds: links
        .map((link) => ({
          id: link.source?.payment?.id!,
          title: link.source?.payment?.uniqueRef,
        }))
        .filter((x) => !!x.id),
      reservationIds: links
        .map((link) => ({
          id: link.source?.reservation?.id!,
          title: link.source?.reservation?.guestName,
        }))
        .filter((x) => !!x.id),
    },
  };
};

export const getLegacyJob = (x: job | jobPlan) =>
  getWorkflowActionRow({
    status: x.status,
    title: x.title,
    id: x.id,
    automationId: x.automationId,
    schema: {
      uniqueRef: 'automation',
    },
    sourceLinks() {
      return [];
    },
    inputJson() {
      return undefined;
    },
    outputJson() {
      return undefined;
    },
  } as any);

export const getWorkflowChangeRow = (
  change: change,
  type: 'synced-from' | 'synced-to'
): WorkflowAction => {
  const source = change.source;

  const reservation = {
    id: change.reservation?.id,
    title: change.reservation?.guestName,
  };

  const payment = {
    id: source?.payment?.id,
    title: source?.payment?.uniqueRef,
  };

  const listing = {
    id: source?.listing?.id,
    title: getListingName(source?.listing, { allowEmpty: true }),
  };

  const syncedFromTitle = [
    toTitleCase(change.source?.type),
    change.source?.description || change.entityUniqueRef,
  ]
    .filter(hasValue)
    .join(' - ');

  const syncedToTitle =
    reservation.title || payment.title || listing.title || syncedFromTitle;

  const title = type === 'synced-from' ? syncedFromTitle : syncedToTitle;

  return {
    createdAt: change.createdAt,
    id: change.id,
    jobPlanId: '',
    jobId: '',
    status: change.status,
    message: change.message || '',
    title,
    type: toTitleCase(change.entityType),
    date: day(change.message).isValid() ? day(change.message).yyyymmdd() : '',
    uniqueRef: change.entityUniqueRef,
    automationId: change.automationId || '',
    inputJson: undefined,
    outputJson: undefined,
    externalHref: change.source?.dataHref,
    links: {
      paymentIds: [payment].filter((x) => !!x.id) as any[],
      reservationIds: [reservation].filter((x) => !!x.id) as any[],
    },
  };
};
